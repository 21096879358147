import { ThemeOption } from "@mui/material";

export default function componentStyleOverrides(theme: ThemeOption) {
	const bgColor = theme.mode === "light" ? theme.colors?.grey50 : theme.colors.darkBackground;
	return {
		MuiAlert: {
			styleOverrides: {
				root: {
					"&.MuiAlert-standardSuccess": {
						background: theme.colors.successDark,
						color: "white",
					},
					"&.MuiAlert-standardInfo": {
						background: theme.colors.primaryMain,
						color: "white",
					},
					"&.MuiAlert-standardError": {
						background: theme.colors.errorMain,
						color: "white",
					},
					"&.MuiAlert-standardWarning": {
						background: theme.colors.warningDark,
						color: theme.colors.grey900,
					},
					".MuiAlert-icon": {
						color: "inherit",
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					borderRadius: "4px",
				},
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				root: {
					backgroundImage: "none",
				},
				rounded: {
					borderRadius: `${theme?.customization?.borderRadius}px`,
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					color: theme.colors?.textDark,
					padding: "24px",
				},
				title: {
					fontSize: "1.125rem",
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: "24px",
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: "24px",
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					color: theme.darkTextPrimary,
					paddingTop: "10px",
					paddingBottom: "10px",
					"&.Mui-selected": {
						color: theme.menuSelected,
						backgroundColor: theme.menuSelectedBack,
						"&:hover": {
							backgroundColor: theme.menuSelectedBack,
						},
						"& .MuiListItemIcon-root": {
							color: theme.menuSelected,
						},
					},
					"&:hover": {
						backgroundColor: theme.menuSelectedBack,
						color: theme.menuSelected,
						"& .MuiListItemIcon-root": {
							color: theme.menuSelected,
						},
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: theme.darkTextPrimary,
					minWidth: "36px",
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					color: theme.textDark,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					color: theme.textDark,
					"&::placeholder": {
						color: theme.darkTextSecondary,
						fontSize: "0.875rem",
					},
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				root: {
					".MuiTablePagination-selectIcon": {
						color: theme.mode === "light" ? theme.colors?.grey700 : theme.colors.darkTextPrimary,
					},
					".MuiTablePagination-actions .Mui-disabled": {
						color: theme.mode === "light" ? theme.colors?.grey700 + 28 : theme.colors?.darkTextSecondary + 28,
					},
					".MuiTablePagination-displayedRows": {
						userSelect: "none",
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					background: bgColor,
					borderRadius: `${theme?.customization?.borderRadius}px`,
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: theme.mode === "light" ? theme.colors?.grey400 : theme.colors.darkTextPrimary + 28,
					},
					"&:hover $notchedOutline": {
						borderColor: theme.colors?.primaryLight,
					},
					"&.MuiInputBase-multiline": {
						padding: 1,
					},
					"& svg": {
						fill: theme.mode === "light" ? "grey" : "white",
					},

					"& .MuiInput-underline:before": {
						borderColor: "red",
					},
					"& .MuiInput-underline:after": {
						borderBottomColor: "green",
					},
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							borderColor: "white",
						},
						"&:hover fieldset": {
							borderColor: "yellow",
						},
						"&.Mui-focused fieldset": {
							borderColor: "green",
						},
					},
				},
				input: {
					fontWeight: 500,
					background: bgColor,
					padding: "15.5px 14px",
					borderRadius: `${theme?.customization?.borderRadius}px`,
					"&.MuiInputBase-inputSizeSmall": {
						padding: "10px 14px",
						"&.MuiInputBase-inputAdornedStart": {
							paddingLeft: 0,
						},
					},
				},
				inputAdornedStart: {
					paddingLeft: 4,
				},
				notchedOutline: {
					borderRadius: `${theme?.customization?.borderRadius}px`,
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					"&:before": {
						borderColor: theme.mode === "light" ? "#c7c7c7" : "#3b4463",
					},
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						color: theme.colors?.grey300,
					},
				},
				mark: {
					backgroundColor: theme.paper,
					width: "4px",
				},
				valueLabel: {
					color: theme?.colors?.primaryLight,
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: theme.divider,
					opacity: theme.mode === "light" ? 1 : 0.2,
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					color: theme.colors?.primaryDark,
					background: theme.colors?.primary200,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					"&.MuiChip-colorDefault:not(.MuiChip-outlined)": {
						backgroundColor: theme.mode === "light" ? "rgba(0, 0, 0, 0.08)" : theme.colors.dark800,
						color: theme.mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#8492c4",
						border: theme.mode === "light" ? "1px solid #eeeeee" : "1px solid #404968",
						"&.MuiChip-deletable .MuiChip-deleteIcon": {
							fill: theme.mode === "light" ? "grey" : "rgba(209, 220, 236, 0.44)",
							"&:hover": {
								fill: theme.mode === "light" ? "#565656" : "rgba(209, 220, 236, 0.7)",
							},
						},
					},
					"&.MuiChip-deletable .MuiChip-deleteIcon": {
						color: "inherit",
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					color: theme.paper,
					background: theme.colors?.grey700,
				},
			},
		},

		MuiSwitch: {
			styleOverrides: {
				switchBase: {
					color: theme.mode === "light" ? "#fff" : "#ccc",
				},

				track: {
					opacity: theme.mode === "light" ? 0.38 : 0.2,
					backgroundColor: theme.mode === "light" ? theme.colors?.background : "#fff",
				},
			},
		},
	};
}
