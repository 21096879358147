import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton, Modal, Theme, useMediaQuery } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppSelector, useCustomTheme } from "../../hooks";

type IntroModalName = "accounts" | "domains" | "trackers" | "offers" | "creatives" | "whitePages" | "antics" | "dashboard";

type IntroModalsStore = {
	[key in IntroModalName]: boolean;
};

interface IIntroModalProps {
	name: IntroModalName;
	modalWidth?: number;
	sx?: SxProps<Theme>;
}
export const IntroModal: React.FC<IIntroModalProps> = ({ name, modalWidth, children, sx }) => {
	const theme = useCustomTheme();
	const { themeMode } = useAppSelector((store) => store.app);
	const [introOpen, setIntroOpen] = useState<boolean>(false);
	const [intoModalsData, setIntoModalsData] = useState<IntroModalsStore | null>(null);

	useEffect(() => {
		if (!name) {
			return;
		}
		const introModalsDataStr = localStorage.getItem("introModals");
		if (introModalsDataStr) {
			const introModalsJson = JSON.parse(introModalsDataStr);
			setIntoModalsData(introModalsJson);
			if (!introModalsJson[name]) {
				setIntroOpen(true);
			}
		} else {
			setIntroOpen(true);
		}
		// eslint-disable-next-line
	}, []);

	const onModalClose = () => {
		if (!name) {
			return;
		}
		setIntroOpen(false);
		localStorage.setItem("introModals", JSON.stringify({ ...intoModalsData, [name]: true }));
	};
	const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Modal
			open={introOpen}
			onClose={() => {
				onModalClose();
			}}
			sx={{ overflowX: "hidden", overflowY: "auto" }}
		>
			<Grid
				container
				maxWidth={modalWidth || (matchDownSm ? 310 : 517)}
				sx={{
					margin: "1.75rem auto",
					position: "relative",
					minHeight: "calc(100% - (1.75rem * 2))",
					display: "flex",
					alignItems: "center",
					pointerEvents: "none",
					outline: "none",
				}}
			>
				<Grid
					item
					onClick={(e) => {
						e.stopPropagation();
					}}
					sx={{
						outline: "none",
						width: "100%",
						pointerEvents: "auto",
						position: "relative",
						...sx,
					}}
				>
					<IconButton
						size="small"
						sx={{ color: "white", position: "absolute", top: "-28px", right: matchDownSm ? 0 : "-28px" }}
						onClick={(e) => {
							onModalClose();
						}}
					>
						<Close fontSize="small" />
					</IconButton>
					<StyledModalContent theme={themeMode}>
						<StyledModalInner theme={themeMode}>{children}</StyledModalInner>
					</StyledModalContent>
				</Grid>
			</Grid>
		</Modal>
	);
};

export const StyledModalContent = styled(Box)`
	border-radius: 11px;
	padding: 60px 30px;
	background: ${(props) =>
		props.theme === "light"
			? "linear-gradient(49.19deg, rgba(204, 231, 252, 0.3) 10.54%, rgba(204, 231, 252, 0) 92.06%), #FFFFFF;"
			: "linear-gradient(49.19deg, #2b2e56 10.54%, #4b5199 92.06%), #36396c"};
	position: relative;
	overflow: hidden;
	&::after {
		content: "";
		position: absolute;
		top: -30px;
		bottom: 0;
		right: 13%;
		width: 377px;
		max-width: 100%;
		background: url("./images/modal-back.png") no-repeat center top;
		background-size: 100% auto;
		opacity: ${(props) => (props.theme === "light" ? 0.07 : 0.2)};
		z-index: 0;
	}
	@media (max-width: 599.95px) {
		padding: 30px 15px;
	}
`;

export const StyledModalInner = styled(Box)`
	position: relative;
	z-index: 1;
	font-size: 18px;
	line-height: 25px;
	color: ${(props) => (props.theme === "light" ? "#212121" : "#BDC8F0")};
	.title {
		font-weight: bold;
		font-size: 70px;
		line-height: 0.8;
		@media (max-width: 599.95px) {
			font-size: 48px;
		}
	}
	.sub-title {
		font-size: 26px;
		line-height: 0.8;
		font-weight: bold;
		small {
			font-size: 18px;
			text-transform: none;
			font-weight: 400;
		}
	}
	.ttu {
		text-transform: uppercase;
	}
	.tar {
		text-align: right;
	}
	.tac {
		text-align: center;
	}
	.vat {
		* {
			vertical-align: top;
		}
	}
	.lh-07 {
		line-height: 0.7;
		display: inline-block;
	}
	.lh-08 {
		line-height: 0.8;
		display: inline-block;
	}
	.price {
		font-weight: bold;
		font-size: 70px;
		line-height: 0.8;
		color: ${(props) => (props.theme === "light" ? "#2196F3" : "#FFC107")};
		@media (max-width: 599.95px) {
			font-size: 48px;
		}
		&.small {
			font-size: 45px;
			line-height: 61px;
		}
		&.small2 {
			font-size: 30px;
		}
		&.green {
			color: rgb(0, 200, 83);
		}
		&.old {
			font-size: 30px;
			color: rgb(189, 200, 240);
			position: relative;
			&::after {
				content: "";
				position: absolute;
				height: 2px;
				left: -10px;
				right: -10px;
				bottom: 0px;
				background: rgb(190, 30, 45);
				transform-origin: 0px 0px;
				transform: rotate(-16deg);
			}
		}
	}
	.old-price {
		font-weight: bold;
		font-size: 45px;
		line-height: 0.8;
	}
	.list {
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		list-style: none;
		padding-left: 40px;
		li {
			position: relative;
			padding-left: 15px;
			&::after {
				content: "";
				position: absolute;
				top: 8px;
				left: 0;
				width: 7px;
				height: 7px;
				background: currentColor;
				border-radius: 50%;
			}
		}
	}
	.item-price {
		font-weight: bold;
		font-size: 16px;
		&:last-of-type {
			padding-left: 10px;
		}
	}
	.list-price {
		font-size: 18px;
		line-height: 35px;
		list-style: none;
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			span:last-of-type {
				padding-left: 10px;
			}
		}
	}
`;
