import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPackState {
	packs: PackDetails[];
	packsLoaded: boolean;
}

const initialState: IPackState = {
	packs: [],
	packsLoaded: false,
};

const pack = createSlice({
	name: "pack",
	initialState,
	reducers: {
		setPacks: (state, action: PayloadAction<PackDetails[]>) => {
			state.packs = action.payload;
		},
		setPacksLoaded: (state, action: PayloadAction<boolean>) => {
			state.packsLoaded = action.payload;
		},
	},
});

export const { setPacks, setPacksLoaded } = pack.actions;

export default pack.reducer;
