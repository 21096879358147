import { IconUpload } from "@tabler/icons";
import { Box, Button, FormHelperText, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { uploadImage } from "../api";
import { CircularProgressWithLabel } from ".";
import { BACKEND_ROOT } from "../store/constants";

const StyledInput = styled("input")({
	display: "none",
});

const StyledImagePreview = styled("img")({
	width: "200px",
	height: "200px",
	borderRadius: "5px",
	objectFit: "cover",
	objectPosition: "center",
	background:
		"linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)",
	backgroundSize: "20px 20px",
	backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px",
});

export const ImageUpload: React.FC<{
	label?: string;
	onFileUploaded?: (filename: string) => void;
	error?: string;
	touched?: boolean;
	onChange?: () => void;
	value?: string;
}> = ({ label = "Загрузить изображение", onFileUploaded = () => {}, onChange = () => {}, error, touched, value }) => {
	const [file, setFile] = useState<File | null | undefined>();
	const [progress, setProgress] = useState<number>(0);
	const [uploaded, setUploaded] = useState<IImageUploadResponse | null>(null);

	const { result, setResult, uploader } = useDisplayImage();

	useEffect(() => {
		if (value) {
			setUploaded({ filename: value, originalname: value });
		} else {
			setUploaded(null);
		}
		setResult(value ? `${BACKEND_ROOT}/assets/${value}` : "");
		setProgress(0);
		setFile(null);
		// eslint-disable-next-line
	}, [value]);

	useEffect(() => {
		if (file) {
			setUploaded(null);
			setProgress(0);

			uploader(file);
			uploadImage(file, (progress) => {
				setProgress(progress);
			}).then((data) => {
				setUploaded(data);
				onFileUploaded(data.filename);
			});
		}
		// eslint-disable-next-line
	}, [file]);

	return (
		<>
			<label htmlFor="upload">
				<StyledInput
					accept="image/*"
					id="upload"
					name="upload"
					type="file"
					onChange={(event) => {
						setFile(event.target.files?.item(0));
						onChange();
					}}
				/>
				<Button variant="outlined" fullWidth component="span" sx={{ p: 2 }}>
					<IconUpload />
					<Typography component="span" sx={{ ml: 1 }}>
						{label}
					</Typography>
				</Button>
			</label>
			{result && (
				<Box sx={{ pt: 2 }}>
					<div style={{ position: "relative", display: "inline-block" }}>
						<StyledImagePreview src={result} alt="image upload preview" style={!uploaded ? { opacity: 0.3 } : { opacity: 1 }} />
						{!uploaded && (
							<CircularProgressWithLabel
								value={progress}
								sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }}
							/>
						)}
					</div>
				</Box>
			)}
			{uploaded && <input type="hidden" name="image" value={uploaded.filename} />}
			{touched && error && (
				<FormHelperText error sx={{ pt: 1 }}>
					{error}
				</FormHelperText>
			)}
		</>
	);
};

function useDisplayImage() {
	const [result, setResult] = useState("");

	function uploader(imageFile: File) {
		const reader = new FileReader();
		reader.addEventListener("load", (e) => {
			setResult(e.target?.result as string);
		});
		reader.readAsDataURL(imageFile);
	}

	return { result, setResult, uploader };
}
