import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useAppSelector, useCustomTheme } from "../../hooks";
import { strengthColor, strengthIndicator } from "../../utils/password-strength";

interface IPasswordInputProps {
	value: string;
	handleBlur: React.FocusEventHandler<HTMLInputElement>;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	label: string;
	id: string;
	name: string;
	error?: boolean;
	touched?: boolean;
	errorMessage?: string;
	withStrengthIndicator?: boolean;
	withEndAdornment?: boolean;
	protectedField?: boolean;
	onProtectedToggle?: (isVisible: boolean) => void;
}
export const PasswordInput: React.FC<IPasswordInputProps> = ({
	value,
	label,
	id,
	name,
	handleBlur,
	handleChange,
	error = false,
	touched = false,
	errorMessage = "",
	withStrengthIndicator = false,
	withEndAdornment = true,
	protectedField = true,
	onProtectedToggle = () => {},
}) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [level, setLevel] = useState<{ label: string; color: string }>();
	const [strength, setStrength] = useState<number>(0);

	useEffect(() => {
		if (!value) {
			setStrength(0);
		}
	}, [value]);

	const theme = useCustomTheme();
	const { themeMode } = useAppSelector((store) => store.app);

	const changePassword = (value: string) => {
		const temp = strengthIndicator(value);
		setStrength(temp);
		setLevel(strengthColor(temp));
	};

	const handleClickShowPassword = () => {
		const nextShowPasswordValue = !showPassword;
		setShowPassword(nextShowPasswordValue);
		onProtectedToggle(nextShowPasswordValue);
	};
	return (
		<>
			<FormControl fullWidth error={error} sx={{ ...theme.typography.customInput }}>
				<InputLabel htmlFor={id}>{label}</InputLabel>
				<OutlinedInput
					id={id}
					type={showPassword || !protectedField ? "text" : "password"}
					value={value}
					name={name}
					label={label}
					onBlur={handleBlur}
					onChange={(e) => {
						handleChange(e as React.ChangeEvent<HTMLInputElement>);
						changePassword(e.target.value);
					}}
					endAdornment={
						withEndAdornment ? (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									edge="end"
									size="large"
									sx={{ color: themeMode === "dark" ? "white" : "black" }}
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						) : null
					}
				/>
				{touched && errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
			</FormControl>
			{withStrengthIndicator && strength !== 0 && (
				<FormControl fullWidth>
					<Box sx={{ mb: 2 }}>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<Box style={{ backgroundColor: level?.color }} sx={{ width: 85, height: 8, borderRadius: "7px" }} />
							</Grid>
							<Grid item>
								<Typography variant="subtitle1" fontSize="0.75rem">
									{level?.label}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</FormControl>
			)}
		</>
	);
};
