import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IRegistrationState {
	registrations: RegistrationDetails[];
	registrationsLoaded: boolean;
}

const initialState: IRegistrationState = {
	registrations: [],
	registrationsLoaded: false,
};

const registration = createSlice({
	name: "registration",
	initialState,
	reducers: {
		setRegistrations: (state, action: PayloadAction<RegistrationDetails[]>) => {
			state.registrations = action.payload;
		},
		updateRegistration: (state, action: PayloadAction<RegistrationDetails>) => {
			state.registrations = [...state.registrations].map((registration) => {
				if (registration._id === action.payload._id) {
					return action.payload;
				} else {
					return registration;
				}
			});
		},
		removeRegistration: (state, action: PayloadAction<RegistrationDetails>) => {
			state.registrations = [...state.registrations].filter((registration) => {
				if (registration._id === action.payload._id) {
					return false;
				} else {
					return true;
				}
			});
		},
		setRegistrationsLoaded: (state, action: PayloadAction<boolean>) => {
			state.registrationsLoaded = action.payload;
		},
	},
});

export const { setRegistrations, updateRegistration, removeRegistration, setRegistrationsLoaded } = registration.actions;

export default registration.reducer;
