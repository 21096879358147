import { useState } from "react";
import * as Yup from "yup";
import { PHRASES } from "../store";
import { newPassword } from "../api";
import { useParams } from "react-router-dom";
import { useAppDispatch } from ".";
import { setUserAuth } from "../redux/slices/app";

export const useAuthNewPassword = () => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const params = useParams();
	const dispatch = useAppDispatch();

	const initialValues = { password: "", passwordConfirmation: "", recaptcha: "", submit: null };
	const validationSchema = Yup.object().shape({
		password: Yup.string().max(255).required(PHRASES.auth_password_required),
		passwordConfirmation: Yup.string()
			.oneOf([Yup.ref("password"), null], PHRASES.auth_confirm_password_invalid)
			.required(PHRASES.auth_confirm_password_required),

		recaptcha: Yup.string().required(PHRASES.auth_recaptcha_required),
	});

	const handlePasswordChangeSubmit = async (data: INewPasswordRequest) => {
		try {
			const responseData = await newPassword(data, params.token);
			const accessToken = responseData.accessToken;
			dispatch(setUserAuth(true));
			localStorage.setItem("token", accessToken);
		} catch (error) {
			throw error;
		}
	};

	return {
		initialValues,

		showPassword,
		validationSchema,
		setShowPassword,
		handlePasswordChangeSubmit,
	};
};
