import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from "@mui/icons-material";
import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useCustomTheme } from "../hooks";

export const BalanceTransactionValue: React.FC<{ data: BalanceTransaction }> = ({ data }) => {
	const theme = useCustomTheme();
	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<Typography variant="subtitle1" component="span" color="inherit" sx={{ lineHeight: "19px" }}>
				{data.type === "dec" ? "" : "+"}
				{data.value.toFixed(2)} {data.unit}
			</Typography>
			<Avatar
				variant="rounded"
				sx={{
					width: 16,
					height: 16,
					borderRadius: "5px",
					backgroundColor: data.type === "inc" ? theme.palette.success.light : theme.palette.orange.light,
					color: data.type === "inc" ? theme.palette.success.dark : theme.palette.orange.dark,
					ml: 1,
				}}
			>
				{data.type === "inc" ? (
					<KeyboardArrowUpOutlined fontSize="small" color="inherit" />
				) : (
					<KeyboardArrowDownOutlined fontSize="small" color="inherit" />
				)}
			</Avatar>
		</Box>
	);
};
