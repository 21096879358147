import { Chip, MaterialColorsEnum, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";

export const RoleChip: React.FC<{ role: UserRole; label?: string; sx?: SxProps<Theme>; otherProps?: any }> = ({
	role,
	label,
	sx = {},
	otherProps = {},
}) => {
	const colors: { [key in UserRole]: MaterialColorsEnum } = {
		user: "success",
		admin: "secondary",
		manager: "primary",
	};
	return <Chip label={label || role} color={colors[role]} variant="filled" size="small" sx={sx} {...otherProps} />;
};
