import { useState } from "react";
import { TablerIcon } from "@tabler/icons";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";

import NavItem from "./NavItem";

import { FiberManualRecord } from "@mui/icons-material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import { constants } from "../../../../store";
import { useAppSelector, useCustomTheme } from "../../../../hooks";
import { MenuItem } from "../../../../typings/menu";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse: React.FC<{ menu: MenuItem; level: number }> = ({ menu, level }) => {
	const theme = useCustomTheme();

	const { themeMode } = useAppSelector((store) => store.app);

	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState<string | null>(null);

	const handleClick = () => {
		setOpen(!open);
		setSelected(!selected ? menu.id : null);
	};

	// menu collapse & item
	const menus = menu.children?.map((item) => {
		switch (item.type) {
			case "collapse":
				return <NavCollapse key={item.id} menu={item} level={level + 1} />;
			case "item":
				return <NavItem key={item.id} item={item} level={level + 1} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				);
		}
	});

	const Icon = menu.icon as TablerIcon;
	const menuIcon = menu.icon ? (
		<Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: "auto", marginBottom: "auto" }} />
	) : (
		<FiberManualRecord
			sx={{
				width: selected === menu.id ? 8 : 6,
				height: selected === menu.id ? 8 : 6,
			}}
			fontSize={level > 0 ? "inherit" : "medium"}
		/>
	);

	const { borderRadius } = constants;

	return (
		<>
			<ListItemButton
				sx={{
					borderRadius: `${borderRadius}px`,
					mb: 0.5,
					alignItems: "flex-start",
					backgroundColor: level > 1 ? "transparent !important" : "inherit",
					py: level > 1 ? 1 : 1.25,
					pl: `${level * 24}px`,
				}}
				selected={selected === menu.id}
				onClick={handleClick}
			>
				<ListItemIcon sx={{ my: "auto", minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
				<ListItemText
					primary={
						<Typography variant={selected === menu.id ? "h5" : "body1"} color="inherit" sx={{ my: "auto" }}>
							{menu.title}
						</Typography>
					}
					secondary={
						menu.caption && (
							<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
								{menu.caption}
							</Typography>
						)
					}
				/>
				{open ? (
					<IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: "auto", marginBottom: "auto" }} />
				) : (
					<IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: "auto", marginBottom: "auto" }} />
				)}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List
					component="div"
					disablePadding
					sx={{
						position: "relative",
						"&:after": {
							content: "''",
							position: "absolute",
							left: "32px",
							top: 0,
							height: "100%",
							width: "1px",
							opacity: themeMode === "light" ? 1 : 0.2,
							background: theme.palette.primary.light,
						},
					}}
				>
					{menus}
				</List>
			</Collapse>
		</>
	);
};

export default NavCollapse;
