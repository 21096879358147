import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const appSlice = createSlice({
	name: "app",
	initialState: {
		// for active default menu in sidebar
		menuGroupsOpen: [],
		// for main sidebar menu
		sidebarMenuOpen: true,
		// for balance modal
		balanceModalOpen: false,
		// color theme
		themeMode: localStorage.getItem("theme") || "dark",
		// user auth
		auth: localStorage.getItem("token") ? true : false,
		// account data
		user: null,
	} as {
		menuGroupsOpen: string[];
		sidebarMenuOpen: boolean;
		balanceModalOpen: boolean;
		themeMode: ThemeMode;
		auth: boolean;
		user: AccountData | null;
	},
	reducers: {
		setSidebarMenuGroupsOpen: (state, action: PayloadAction<string>) => {
			state.menuGroupsOpen = [action.payload];
		},

		setSidebarMenuOpen: (state, action: PayloadAction<boolean>) => {
			state.sidebarMenuOpen = action.payload;
		},

		setThemeMode: (state, action: PayloadAction<ThemeMode>) => {
			localStorage.setItem("theme", action.payload);
			state.themeMode = action.payload;
		},

		setUserAuth: (state, action: PayloadAction<boolean>) => {
			state.auth = action.payload;
		},

		setUserData: (state, action: PayloadAction<AccountData | null>) => {
			state.user = action.payload;
		},

		setUserBalance: (state, action: PayloadAction<BalanceItem>) => {
			if (!state.user) {
				return;
			}
			const prevBalance = state.user?.balance[0] as BalanceItem;
			state.user = { ...state.user, balance: [{ ...prevBalance, value: action.payload.value }] };
		},
		setBalanceModalOpen: (state, action: PayloadAction<boolean>) => {
			state.balanceModalOpen = action.payload;
		},
	},
});

export const { setSidebarMenuGroupsOpen, setSidebarMenuOpen, setThemeMode, setUserAuth, setUserData, setUserBalance, setBalanceModalOpen } = appSlice.actions;

export default appSlice.reducer;
