import { lazy } from "react";
import { Navigate } from "react-router";

import MainLayout from "../layout/MainLayout";
import { Loadable } from "../ui-component";

const Dashboard = Loadable(lazy(() => import("../views/pages/Dashboard")));
const History = Loadable(lazy(() => import("../views/pages/History")));
const Accounts = Loadable(lazy(() => import("../views/pages/Accounts")));
const Domains = Loadable(lazy(() => import("../views/pages/Domains")));
const Tracker = Loadable(lazy(() => import("../views/pages/Tracker")));
const Offers = Loadable(lazy(() => import("../views/pages/Offers")));

const Creatives = Loadable(lazy(() => import("../views/pages/Creatives")));
const WhitePages = Loadable(lazy(() => import("../views/pages/WhitePages")));
const Antic = Loadable(lazy(() => import("../views/pages/Antic")));
const Settings = Loadable(lazy(() => import("../views/pages/Settings")));
const Plans = Loadable(lazy(() => import("../views/pages/Plans")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (auth: boolean) => {
	return {
		path: "/",
		element: auth ? <MainLayout /> : <Navigate to="login" replace />,
		children: [
			{
				path: "/",
				element: <Dashboard />,
			},
			{
				path: "/dashboard",
				element: <Dashboard />,
			},
			{
				path: "/history",
				element: <History />,
			},
			{
				path: "/accounts",
				element: <Accounts />,
			},
			{
				path: "/domains",
				element: <Domains />,
			},
			{
				path: "/tracker",
				element: <Tracker />,
			},
			{
				path: "/offers",
				element: <Offers />,
			},
			{
				path: "/creatives",
				element: <Creatives />,
			},
			{
				path: "/white-pages",
				element: <WhitePages />,
			},
			{
				path: "/antic",
				element: <Antic />,
			},
			{
				path: "/tariffs",
				element: <Plans />,
			},
			{
				path: "/settings",
				element: <Settings />,
			},
		],
	};
};

export default MainRoutes;
