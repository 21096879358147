import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { useCustomTheme } from "../../hooks";
type OptionValue = string | number;

type Option<T extends OptionValue> = {
	value: T;
	label: string;
};

interface Props<T extends OptionValue> {
	renderItem?: (item: Option<T>) => React.ReactNode;
	handleChange: (event: SelectChangeEvent<T>, child: React.ReactNode) => void;
	data: Option<T>[];
	id: string;
	label: string;
	name: string;
	value: T;
	error?: string;
	touched?: boolean;
}

export const SelectField = <T extends OptionValue>({
	data,
	error,
	touched,
	label,
	id,
	name,
	value,
	handleChange,

	renderItem,
}: Props<T>) => {
	const theme = useCustomTheme();

	return (
		<FormControl fullWidth error={Boolean(touched && error)} sx={{ ...theme.typography.customInput }}>
			<InputLabel htmlFor={id}>{label}</InputLabel>
			<Select labelId={`${id}-label`} id={id} value={value} onChange={handleChange} input={<OutlinedInput label={label} name={name} />}>
				{data.map((item, idx) => (
					<MenuItem key={idx} value={item.value}>
						{renderItem ? renderItem(item) : item.label}
					</MenuItem>
				))}
			</Select>
			{touched && error && <FormHelperText error>{error}</FormHelperText>}
		</FormControl>
	);
};
