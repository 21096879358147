import React from "react";
import { Box, TextField, Autocomplete, FormControl, FormHelperText } from "@mui/material";
import { countries } from "../../store";
import { CountryType } from "../../store/countries";
import { useCustomTheme } from "../../hooks";
import { CountryChip } from "..";
import { SxProps } from "@mui/system";

export const CountrySelect: React.FC<{
	withPhoneCode?: boolean;
	label?: string;
	placeholder?: string;
	id?: string;
	limitTags?: number;
	size?: "small" | "medium";
	chipSize?: "small" | "medium";
	sx?: SxProps;
	inputSx?: SxProps;
	error?: string;
	touched?: boolean;
	handleBlur?: (e: React.FocusEvent<any>) => void;
	onChange?: (selected: CountryType[]) => void;
	selected?: string[];
}> = ({
	withPhoneCode = false,
	error = "",
	touched,
	label = "Выбор страны",
	placeholder = "Поиск",
	id = "country-select",
	limitTags,
	size,
	chipSize = "small",
	sx = {},
	inputSx = {},
	onChange = () => {},
	handleBlur = () => {},
	selected = [],
}) => {
	const theme = useCustomTheme();
	const imgSrcSvg = (option: CountryType) => `https://flagcdn.com/${option.code.toLowerCase()}.svg`;
	return (
		<FormControl fullWidth sx={{ marginBottom: 1, marginTop: 1, ...sx }}>
			<Autocomplete
				size={size}
				multiple
				id={id}
				options={countries}
				limitTags={limitTags}
				autoHighlight
				getOptionLabel={(option) => option.label}
				onBlur={handleBlur}
				value={
					selected
						.map((country) => {
							const countryItem = countries.find((item) => item.code === country);
							return countryItem || null;
						})
						.filter((item) => Boolean(item)) as CountryType[]
				}
				filterOptions={(options, state) => {
					const searchStr = state.inputValue.toLowerCase();
					return options.filter((item) => {
						const code = item.code.toLowerCase();
						const country = item.label.toLowerCase();
						return code.startsWith(searchStr) || country.startsWith(searchStr);
					});
				}}
				sx={{ ...theme.typography.customInput, marginBottom: 0, marginTop: 0 }}
				renderOption={(props, option) => (
					<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
						<img loading="lazy" width="20" src={imgSrcSvg(option)} alt={option.code} />
						{option.label} ({option.code}) {withPhoneCode && "+" + option.phone}
					</Box>
				)}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => {
						return <CountryChip code={option.code} sx={{ mr: 1 }} size={chipSize} key={index} otherProps={{ ...getTagProps({ index }) }} />;
					})
				}
				renderInput={(params) => (
					<>
						<TextField
							{...params}
							error={Boolean(error && touched)}
							label={label}
							placeholder={placeholder}
							sx={inputSx}
							inputProps={{
								...params.inputProps,
								autoComplete: "new-password", // disable autocomplete and autofill
							}}
						/>
						{touched && error && <FormHelperText error>{error}</FormHelperText>}
					</>
				)}
				onChange={(e, value) => {
					onChange(value);
				}}
			/>
		</FormControl>
	);
};
