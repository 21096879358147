import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { hideNotification } from "../redux/slices/notification";

export const Notifications: React.FC<{}> = () => {
	const { message, open, type } = useAppSelector((store) => store.notification);
	const dispatch = useAppDispatch();

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={() => {
				dispatch(hideNotification());
			}}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Alert
				onClose={() => {
					dispatch(hideNotification());
				}}
				severity={type || "success"}
				sx={{ width: "100%" }}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};
