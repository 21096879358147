// assets
import { IconUsers, IconCashBanknote, IconNews, IconServer, IconUserPlus } from "@tabler/icons";
import { MenuGroup } from "../typings/menu";

const admin: MenuGroup = {
	id: "admin-menu",
	type: "group",
	title: "Администрирование",
	caption: "Раздел доступный администраторам и менеджерам",

	children: [
		{
			id: "users",
			title: "Пользователи",
			type: "item",
			url: "/users",
			icon: IconUsers,
		},
		{
			id: "balance",
			title: "Баланс",
			type: "item",
			url: "/balance",
			icon: IconCashBanknote,
		},
		{
			id: "news",
			title: "Новости",
			type: "item",
			url: "/news",
			icon: IconNews,
		},
		{
			id: "domains-admin",
			title: "Домены",
			type: "item",
			url: "/domains/admin",
			icon: IconServer,
		},
		{
			id: "registrations",
			title: "Регистрации",
			type: "item",
			url: "/registrations",
			icon: IconUserPlus,
		},
	],
};

export default admin;
