import { FormHelperText } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const SubmitError: React.FC<{ error?: string }> = ({ error }) => {
	return error ? (
		<Box sx={{ mt: 3 }}>
			<FormHelperText error>{error}</FormHelperText>
		</Box>
	) : null;
};
