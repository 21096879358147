import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IWhitePageOrder {
	orders: WhitePageOrder[];
	ordersLoaded: boolean;
}
const initialState: IWhitePageOrder = {
	orders: [],
	ordersLoaded: false,
};

const whitePage = createSlice({
	name: "whitePage",
	initialState,
	reducers: {
		setWhitePageOrders: (state, action: PayloadAction<WhitePageOrder[]>) => {
			state.orders = action.payload;
		},
		addWhitePageOrder: (state, action: PayloadAction<WhitePageOrder>) => {
			state.orders = [action.payload, ...state.orders];
		},
		setOrdersLoaded: (state, action: PayloadAction<boolean>) => {
			state.ordersLoaded = action.payload;
		},
	},
});

export const { setWhitePageOrders, addWhitePageOrder, setOrdersLoaded } = whitePage.actions;
export default whitePage.reducer;
