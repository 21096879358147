import { useRoutes } from "react-router-dom";
import { PartialRouteObject } from "react-router";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { useAppSelector } from "../hooks";
import ManagerRoutes from "./ManagerRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
	const { auth, user } = useAppSelector((store) => store.app);

	const roleBasedRoutes = (targetRole: UserRole, routes: PartialRouteObject) => {
		if (!user) {
			return null;
		}
		if (user?.role === targetRole || user?.role === "admin") {
			return routes;
		}
		return null;
	};

	const routes = [MainRoutes(auth), AuthenticationRoutes(auth), roleBasedRoutes("manager", ManagerRoutes(auth))].filter((routes) =>
		Boolean(routes),
	);

	return useRoutes(routes as PartialRouteObject[]);
}
