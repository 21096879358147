import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAnticState {
	antics: AnticDetails[];
	anticsLoaded: boolean;
}
const initialState: IAnticState = {
	antics: [],
	anticsLoaded: false,
};

const antic = createSlice({
	name: "antic",
	initialState,
	reducers: {
		setAntics: (state, action: PayloadAction<AnticDetails[]>) => {
			state.antics = action.payload;
		},

		setAnticsLoaded: (state, action: PayloadAction<boolean>) => {
			state.anticsLoaded = action.payload;
		},
	},
});

export const { setAntics, setAnticsLoaded } = antic.actions;

export default antic.reducer;
