import { createTheme } from "@mui/material/styles";
import { ThemeOption, CustomThemeOptions } from "@mui/material";

// assets
import colors from "../assets/scss/_themes-vars.module.scss";
import { constants } from "../store";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 */

export const theme = (mode: ThemeMode) => {
	const color = colors;

	const themeConfig =
		mode === "light"
			? {
					heading: color.grey900,
					paper: color.paper,
					backgroundDefault: color.paper,
					background: color.primaryLight,
					darkTextPrimary: color.grey700,
					darkTextSecondary: color.grey500,
					textDark: color.grey900,
					menuSelected: color.secondaryDark,
					menuSelectedBack: color.secondaryLight,
					divider: color.grey200,
					cardBorder: (color.primary200 as string) + 75,
			  }
			: {
					heading: color.darkTextTitle,
					paper: color.darkLevel2,
					backgroundDefault: color.darkPaper,
					background: color.darkBackground,
					darkTextPrimary: color.darkTextPrimary,
					darkTextSecondary: color.darkTextSecondary,
					textDark: color.darkTextTitle,
					menuSelected: color.darkSecondaryMain,
					menuSelectedBack: color.darkSecondaryMain + 15,
					divider: color.darkTextPrimary,
					cardBorder: color.darkPaper,
			  };

	const themeOption: ThemeOption = {
		mode,
		colors: color,
		...themeConfig,
		customization: { borderRadius: constants.borderRadius },
	};

	const themeOptions: CustomThemeOptions = {
		direction: "ltr",
		palette: themePalette(themeOption),
		mixins: {
			toolbar: {
				minHeight: "48px",
				padding: "16px",
				"@media (min-width: 600px)": {
					minHeight: "48px",
				},
			},
		},
		config: themeOption,
		typography: themeTypography(themeOption),
	};

	const themes = createTheme(themeOptions);
	themes.components = componentStyleOverrides(themeOption);

	return themes;
};

export default theme;
