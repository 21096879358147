import { FormControl, FormControlLabel, FormHelperText, FormLabel, Switch } from "@mui/material";
import React from "react";

interface ISwitchFieldProps {
	label: string;
	name: string;
	id: string;
	checked: boolean;
	handleChange: (e: React.ChangeEvent<any>) => void;
	fieldLabel?: string;
	error?: string;
}

export const SwitchField: React.FC<ISwitchFieldProps> = ({ label, fieldLabel, name, id, checked, error, handleChange }) => {
	return (
		<FormControl component="div" sx={{ my: 1 }}>
			{fieldLabel && (
				<FormLabel component="legend" sx={{ ml: 2 }}>
					{fieldLabel}
				</FormLabel>
			)}
			<FormControlLabel
				value="start"
				control={<Switch color="primary" checked={checked} name={name} id={id} onChange={handleChange} />}
				label={label}
				labelPlacement="start"
			/>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	);
};
