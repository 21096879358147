import { Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { IconCheck } from "@tabler/icons";
import React from "react";
import { useCustomTheme } from "../../hooks";

export const FeaturesList: React.FC<{ features: TrackerFeature[] }> = ({ features }) => {
	const theme = useCustomTheme();
	return (
		<List dense sx={{ mx: -1, mb: 3 }}>
			{features.map((feature, idx) => {
				return (
					<React.Fragment key={idx}>
						<ListItem sx={{ px: 0.5, opacity: feature.active ? 1 : 0.3 }}>
							<ListItemIcon>
								<IconCheck color={feature.active ? theme.palette.success.main : undefined} style={{ opacity: feature.active ? 1 : 0.3 }} />
							</ListItemIcon>
							<ListItemText primary={feature.label} />
						</ListItem>
						<Divider />
					</React.Fragment>
				);
			})}
		</List>
	);
};
