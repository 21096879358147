import { FormControl, FormHelperText, InputBaseComponentProps, InputLabel, OutlinedInput } from "@mui/material";
import React from "react";
import { useCustomTheme } from "../../hooks";
interface ITextInputProps {
	id: string;
	value: string | number;
	name: string;
	label: string;
	handleChange: (e: React.ChangeEvent<any>) => void;
	type?: "text" | "email" | string;
	error?: string;
	touched?: boolean;
	inputProps?: InputBaseComponentProps;
	handleBlur?: (e: React.FocusEvent<any>) => void;
	multiline?: boolean;
	disabled?: boolean;
}
export const TextInput: React.FC<ITextInputProps> = ({
	id,
	value,
	touched,
	error,
	label,
	name,
	disabled = false,
	multiline = false,
	type = "text",
	handleChange,
	handleBlur,
	inputProps,
}) => {
	const theme = useCustomTheme();
	return (
		<FormControl fullWidth error={Boolean(touched && error)} sx={{ ...theme.typography.customInput }}>
			<InputLabel htmlFor={id}>{label}</InputLabel>
			<OutlinedInput
				id={id}
				disabled={disabled}
				type={type}
				value={value}
				name={name}
				onBlur={handleBlur}
				onChange={handleChange}
				inputProps={inputProps}
				multiline={multiline}
				
			/>
			{touched && error && <FormHelperText error>{error}</FormHelperText>}
		</FormControl>
	);
};
