export const currencyList: {
	label: string;
	value: CurrencyUnit;
	sign: string;
}[] = [
	{
		label: "USD",
		value: "USD",
		sign: "$",
	},
	{
		label: "руб",
		value: "RUB",
		sign: "₽",
	},
];
