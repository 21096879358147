import { AlertProps } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface INotificationSliceState {
	message: string;
	open: boolean;
	type: AlertProps["severity"];
}

const initialState: INotificationSliceState = {
	message: "",
	open: false,
	type: "success",
};

const notification = createSlice({
	name: "notification",
	initialState,
	reducers: {
		setErrorMessage: (state, action: PayloadAction<string>) => {
			state.message = action.payload;
			state.type = "error";
			state.open = true;
		},
		setNotification: (state, action: PayloadAction<string>) => {
			state.message = action.payload;
			state.type = "success";
			state.open = true;
		},
		showNotification: (state) => {
			state.type = "success";
			state.open = true;
		},
		hideNotification: (state) => {
			state.open = false;
		},
	},
});

export const { setNotification, setErrorMessage, showNotification, hideNotification } = notification.actions;
export default notification.reducer;
