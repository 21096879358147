// assets
import { IconDashboard, IconUsers, IconServer, IconEye, IconTag, IconBoxModel, IconBoxMultiple, IconNotes, IconDiscount2 } from "@tabler/icons";
import { MenuGroup } from "../typings/menu";

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard: MenuGroup = {
	id: "dashboard",
	title: "Разделы",
	type: "group",
	children: [
		{
			id: "default",
			title: "Дашборд",
			type: "item",
			url: "/dashboard",
			icon: IconDashboard,
		},
		{
			id: "accounts",
			title: "Аккаунты",
			type: "item",
			url: "/accounts",
			icon: IconUsers,
		},
		{
			id: "domains",
			title: "Домены",
			type: "item",
			url: "/domains",
			icon: IconServer,
		},
		{
			id: "tracker",
			title: "Трекер",
			type: "item",
			url: "/tracker",
			icon: IconEye,
		},
		{
			id: "offers",
			title: "Офферы",
			type: "item",
			url: "/offers",
			icon: IconTag,
		},
		{
			id: "creatives",
			title: "Креативы",
			type: "item",
			url: "/creatives",
			icon: IconBoxModel,
		},
		{
			id: "white-page",
			title: "Вайт пейджи",
			type: "item",
			url: "/white-pages",
			icon: IconBoxMultiple,
		},
		{
			id: "antic",
			title: "Антик",
			type: "item",
			url: "/antic",
			icon: IconNotes,
		},
		{
			id: "tariffs",
			title: "Вместе дешевле",
			type: "item",
			url: "/tariffs",
			icon: IconDiscount2,
		},
	],
};

export default dashboard;
