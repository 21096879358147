import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import { NavigationScroll } from "./layout/NavigationScroll";
import { useApp, useAppSelector } from "./hooks";
import { Notifications } from "./ui-component";

// ==============================|| APP ||============================== //

const App: React.FC<{}> = () => {
	const { themeMode } = useAppSelector((store) => store.app);
	useApp();
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes(themeMode)}>
				<CssBaseline />
				<NavigationScroll>
					<Routes />
				</NavigationScroll>
				<Notifications />
			</ThemeProvider>
		</StyledEngineProvider>
	);
};
export default App;
