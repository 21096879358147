import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";

import _ from "lodash";
import { useAppSelector, useCustomTheme } from "../hooks";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import { VerticalMarquee } from ".";

const StyledMarqueeItem = styled.img`
	margin-right: 16px;
	border: 1px solid ${(props) => (props.theme === "light" ? "rgba(144, 202, 249, 0.46)" : "#111936")};
	border-radius: 11px;
	overflow: hidden;
	height: 180px;
	width: auto;
	object-fit: contain;
`;

export const ImagesGallery: React.FC<{ images: string[]; columnWidth?: number }> = ({ images, columnWidth = 285 }) => {
	const [height, setHeight] = useState(0);
	const [showCount, setShowCount] = useState<number>(3);
	const ref = useRef<HTMLDivElement>(null);
	const theme = useCustomTheme();
	const { themeMode } = useAppSelector((store) => store.app);

	const memoizedShuffles = useMemo(() => {
		return [...Array(5)].map((el) => _.shuffle(images));
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setHeight(ref.current?.clientHeight || 0);
		handleShowCount();
		// eslint-disable-next-line
	}, []);

	const handleShowCount = () => {
		const containerWidth = ref.current?.clientWidth || 0;
		const count = Math.floor(containerWidth / (columnWidth + 15));
		setShowCount(Math.max(1, Math.min(count, 5)));
	};

	const resizeHandler = (e: UIEvent) => {
		handleShowCount();
		setHeight(ref.current?.clientHeight || 0);
	};

	useEffect(() => {
		window.addEventListener("resize", resizeHandler);
		return () => {
			window.removeEventListener("resize", resizeHandler);
		};
	});

	const marqueeHeight = height * 0.9;

	const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box sx={{ height: "100%", whiteSpace: "nowrap" }} ref={ref}>
			{!matchDownMd && showCount ? (
				[...Array(showCount)].map((el, idx) => (
					<VerticalMarquee
						key={idx}
						height={marqueeHeight}
						marqueeProps={{ speed: 3, direction: idx % 2 === 0 ? "left" : "right" }}
						images={memoizedShuffles[idx]}
						width={columnWidth}
						containerStyle={idx < showCount - 1 ? { marginRight: "15px" } : {}}
					/>
				))
			) : (
				<>
					<Marquee
						gradientWidth={92}
						gradientColor={themeMode === "dark" ? [26, 34, 63] : [227, 242, 253]}
						style={{ marginBottom: 16, userSelect: "none" }}
					>
						{memoizedShuffles[0].map((url, idx) => (
							<StyledMarqueeItem theme={themeMode} src={url} draggable="false" alt="gallery" key={idx} />
						))}
					</Marquee>
					<Marquee
						gradientWidth={92}
						direction="right"
						gradientColor={themeMode === "dark" ? [26, 34, 63] : [227, 242, 253]}
						style={{ userSelect: "none" }}
					>
						{memoizedShuffles[1].map((url, idx) => (
							<StyledMarqueeItem theme={themeMode} src={url} draggable="false" alt="gallery" key={idx} />
						))}
					</Marquee>
				</>
			)}
		</Box>
	);
};
