import { Link } from "@mui/material";
import React from "react";
import { RoleChip } from "..";

export const UserInfo: React.FC<{ user: UserData }> = ({ user }) => {
	return (
		<>
			<RoleChip role={user.role} sx={{ height: 15 }} />
			<br />
			<Link href={`mailto:${user.email}`} target="_blank" rel="noreferrer">
				{user.email}
			</Link>
		</>
	);
};
