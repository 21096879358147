import { useCustomTheme } from "../hooks";
import logoDark from "../assets/images/logo-dark.svg";
import logoLight from "../assets/images/logo-light.svg";

// ==============================|| LOGO SVG ||============================== //

const Logo: React.FC<{}> = () => {
	const theme = useCustomTheme();

	return <img src={theme.config.mode === "light" ? logoLight : logoDark} alt="Com.Unity" width="150" />;
};

export default Logo;
