import { MenuGroup } from "../typings/menu";
import admin from "./admin";
import dashboard from "./dashboard";
import other from "./other";

// ==============================|| MENU ITEMS ||============================== //

const protectedMenuGroup = (userRole: UserRole, openFor: UserRole[], menu: MenuGroup) => {
	if (openFor.includes(userRole) || userRole === "admin") {
		return menu;
	}

	return null;
};

const getMenuItems = (userRole: UserRole | undefined) => {
	const defaultMenu = [dashboard, other];
	if (!userRole) {
		return {
			items: defaultMenu,
		};
	}
	const adminMenu = protectedMenuGroup(userRole, ["manager"], admin);
	if (adminMenu) {
		defaultMenu.push(adminMenu);
	}
	return {
		items: defaultMenu,
	};
};

export default getMenuItems;
