import { Avatar, Box, Button, styled, Typography } from "@mui/material";
import { alpha } from "@mui/system";
import React from "react";
import { FeaturesList, MainCard } from "..";
import { useAppSelector, useCustomTheme } from "../../hooks";

const StyledTitle = styled(Typography)(() => {
	return {
		position: "relative",
		paddingBottom: "20px",
		textTransform: "capitalize",
		"&:after": {
			content: '""',
			position: "absolute",
			width: "50px",
			height: "4px",
			background: "#2196f3",
			left: "50%",
			bottom: "0px",
			transform: "translateX(-50%)",
		},
	};
});

export const ToolCard: React.FC<{
	icon: React.ReactNode;
	data: TrackerDetails | AnticDetails;
	onSelected: () => void;
}> = ({ icon, data, onSelected }) => {
	const theme = useCustomTheme();
	const { themeMode } = useAppSelector((store) => store.app);
	const { description, title, features, price } = data;
	const isRecommended = Object.prototype.hasOwnProperty.call(data, "recommended") ? (data as TrackerDetails).recommended : false;

	const bgColor = themeMode === "light" ? alpha(theme.palette.primary.main as string, 0.1) : theme.palette.dark.main;
	return (
		<MainCard boxShadow active={isRecommended}>
			<Box sx={{ textAlign: "center" }}>
				<Avatar
					sx={{
						width: 80,
						height: 80,
						bgcolor: bgColor,
						margin: "auto",
						mb: 3,
					}}
				>
					{icon}
				</Avatar>
				<StyledTitle variant="h3" sx={{ mb: 3 }}>
					{title}
				</StyledTitle>
				{description && (
					<Typography variant="body1" sx={{ mb: 2 }}>
						{description}
					</Typography>
				)}
				<Typography variant="h4" sx={{ mb: 2 }}>
					<Typography component="span" variant="h2">
						{price.value} {price.currency}
					</Typography>{" "}
					/ месяц
				</Typography>
				<FeaturesList features={features} />
				<Button
					variant="outlined"
					onClick={() => {
						onSelected();
					}}
				>
					Заказать
				</Button>
			</Box>
		</MainCard>
	);
};
