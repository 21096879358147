import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ModalProps } from "@mui/material";
import React from "react";

export const ConfirmDialog: React.FC<{
	open: boolean;
	handleClose: ModalProps["onClose"];
	onConfirm: () => void;
	title: string;
	description: string;
}> = ({ open, handleClose, onConfirm, title, description }) => {
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{title}</DialogTitle>

			<DialogContent>
				<DialogContentText sx={{ whiteSpace: "pre-line" }}>{description}</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button
					autoFocus
					onClick={(e) => {
						handleClose && handleClose(e, "escapeKeyDown");
					}}
				>
					Отмена
				</Button>
				<Button
					onClick={(e) => {
						handleClose && handleClose(e, "escapeKeyDown");
						onConfirm();
					}}
				>
					Продолжить
				</Button>
			</DialogActions>
		</Dialog>
	);
};
