import { forwardRef } from "react";

// material-ui
import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { useCustomTheme } from "../../hooks";

// constant
const headerSX = {
	"& .MuiCardHeader-action": { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //
interface IMainCardProps {
	border?: boolean;
	boxShadow?: boolean;
	children?: React.ReactNode;
	subheader?: React.ReactNode;
	content?: boolean;
	contentClass?: string;
	contentSX?: SxProps;
	darkTitle?: boolean;
	secondary?: React.ReactNode | string;
	shadow?: string;
	sx?: SxProps;
	title?: React.ReactNode | string;
	elevation?: number;
	active?: boolean;
}
const MainCard = forwardRef<HTMLDivElement, IMainCardProps>(
	(
		{
			border = true,
			boxShadow,
			children,
			content = true,
			subheader = null,
			active = false,
			contentClass = "",
			contentSX = {},
			darkTitle,
			secondary,
			shadow,
			sx = {},
			title,
			...others
		},
		ref,
	) => {
		const theme = useCustomTheme();

		return (
			<Card
				ref={ref}
				{...others}
				sx={{
					border: border ? "1px solid" : "none",
					borderColor: active ? theme.palette.primary.main : theme.config.cardBorder,
					":hover": {
						boxShadow: boxShadow ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)" : "inherit",
					},
					...sx,
				}}
			>
				{/* card header and action */}
				{!darkTitle && title && <CardHeader sx={headerSX} title={title} subheader={subheader} action={secondary} />}
				{darkTitle && title && (
					<CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} subheader={subheader} action={secondary} />
				)}

				{/* content & header divider */}
				{title && <Divider />}

				{/* card content */}
				{content && (
					<CardContent sx={contentSX} className={contentClass}>
						{children}
					</CardContent>
				)}
				{!content && children}
			</Card>
		);
	},
);

export default MainCard;
