import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ITrackerState {
	trackers: TrackerDetails[];
	trackersLoaded: boolean;
}
const initialState: ITrackerState = {
	trackers: [],
	trackersLoaded: false,
};

const tracker = createSlice({
	name: "tracker",
	initialState,
	reducers: {
		setTrackers: (state, action: PayloadAction<TrackerDetails[]>) => {
			state.trackers = action.payload;
		},

		setTrackersLoaded: (state, action: PayloadAction<boolean>) => {
			state.trackersLoaded = action.payload;
		},
	},
});

export const { setTrackers, setTrackersLoaded } = tracker.actions;

export default tracker.reducer;
