import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { AppBar, Box, CssBaseline, CustomTheme, Toolbar, useMediaQuery } from "@mui/material";

import Header from "./Header";
import Sidebar from "./Sidebar";
import { setSidebarMenuOpen } from "../../redux/slices/app";
import { useAppDispatch, useAppSelector, useCustomTheme } from "../../hooks";
import { constants } from "../../store";

// styles
const StyledMain = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{ open: boolean }>(({ theme, open }) => {
	const { drawerWidth } = constants;
	const customTheme = theme as unknown as CustomTheme;
	return {
		...customTheme.typography.mainContent,
		...(!open && {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			[theme.breakpoints.up("md")]: {
				marginLeft: -(drawerWidth - 20),
				width: `calc(100% - ${drawerWidth}px)`,
			},
			[theme.breakpoints.down("md")]: {
				marginLeft: "20px",
				width: `calc(100% - ${drawerWidth}px)`,
				padding: "16px",
			},
			[theme.breakpoints.down("sm")]: {
				marginLeft: "10px",
				width: `calc(100% - ${drawerWidth}px)`,
				padding: "16px",
				marginRight: "10px",
			},
		}),
		...(open && {
			transition: theme.transitions.create("margin", {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			width: `calc(100% - ${drawerWidth}px)`,
			[theme.breakpoints.down("md")]: {
				marginLeft: "20px",
			},
			[theme.breakpoints.down("sm")]: {
				marginLeft: "10px",
			},
		}),
	};
});

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout: React.FC<{}> = () => {
	const theme = useCustomTheme();
	const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
	const dispatch = useAppDispatch();

	// Handle left drawer
	const { sidebarMenuOpen } = useAppSelector((store) => store.app);

	const handleLeftDrawerToggle = () => {
		dispatch(setSidebarMenuOpen(!sidebarMenuOpen));
	};

	useEffect(() => {
		dispatch(setSidebarMenuOpen(!matchDownMd));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchDownMd]);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />

			<AppBar
				enableColorOnDark
				position="fixed"
				color="inherit"
				elevation={0}
				sx={{
					bgcolor: theme.palette.background.default,
					transition: sidebarMenuOpen ? theme.transitions.create("width") : "none",
				}}
			>
				<Toolbar>
					<Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
				</Toolbar>
			</AppBar>

			<Sidebar drawerOpen={sidebarMenuOpen} drawerToggle={handleLeftDrawerToggle} />

			<StyledMain open={sidebarMenuOpen}>
				<Outlet />
			</StyledMain>
		</Box>
	);
};

export default MainLayout;
