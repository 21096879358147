import { lazy } from "react";

// project imports
import { Loadable } from "../ui-component";
import MinimalLayout from "../layout/MinimalLayout";
import { Navigate } from "react-router";

// login routing
const AuthLogin = Loadable(lazy(() => import("../views/pages/authentication/LoginPage")));
const AuthRegister = Loadable(lazy(() => import("../views/pages/authentication/RegisterPage")));
const ForgotPassword = Loadable(lazy(() => import("../views/pages/authentication/ForgotPasswordPage")));
const ResetPassword = Loadable(lazy(() => import("../views/pages/authentication/ResetPasswordPage")));
const SuccessPage = Loadable(lazy(() => import("../views/pages/authentication/SuccessPage")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = (auth: boolean) => {
	return {
		path: "/",
		element: !auth ? <MinimalLayout /> : <Navigate to="dashboard" />,
		children: [
			{
				path: "/login",
				element: <AuthLogin />,
			},
			{
				path: "/register",
				element: <AuthRegister />,
			},
			{
				path: "/forgot-password",
				element: <ForgotPassword />,
			},
			{
				path: "/reset-password/:token",
				element: <ResetPassword />,
			},
			{
				path: "/success",
				element: <SuccessPage />,
			},
		],
	};
};

export default AuthenticationRoutes;
