import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface INewsSliceState {
	// for admin
	news: NewsDetails[];
	newsLoaded: boolean;

	// for dashboard
	recommendations: DailyNews[];
	recommendationsLoaded: boolean;
}

const initialState: INewsSliceState = {
	news: [],
	newsLoaded: false,

	recommendations: [],
	recommendationsLoaded: false,
};

const news = createSlice({
	name: "news",
	initialState,
	reducers: {
		setNews: (state, action: PayloadAction<NewsDetails[]>) => {
			state.news = action.payload;
		},
		addNews: (state, action: PayloadAction<NewsDetails>) => {
			state.news = [action.payload, ...state.news];
		},
		saveNews: (state, action: PayloadAction<NewsDetails>) => {
			state.news = state.news.map((post) => {
				if (post._id === action.payload._id) {
					return action.payload;
				} else {
					return post;
				}
			});
		},
		setNewsLoaded: (state, action: PayloadAction<boolean>) => {
			state.newsLoaded = action.payload;
		},
		setRecommendations: (state, action: PayloadAction<DailyNews[]>) => {
			state.recommendations = action.payload;
		},
		setRecommendationsLoaded: (state, action: PayloadAction<boolean>) => {
			state.recommendationsLoaded = action.payload;
		},
	},
});

export const { setNews, addNews, saveNews, setNewsLoaded, setRecommendations, setRecommendationsLoaded } = news.actions;

export default news.reducer;
