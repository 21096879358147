import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { TablerIcon } from "@tabler/icons";

import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useAppDispatch, useAppSelector, useCustomTheme } from "../../../../hooks";
import { setSidebarMenuGroupsOpen, setSidebarMenuOpen } from "../../../../redux/slices/app";
import { constants } from "../../../../store";
import { MenuItem } from "../../../../typings/menu";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem: React.FC<{ item: MenuItem; level: number }> = ({ item, level }) => {
	const theme = useCustomTheme();
	const dispatch = useAppDispatch();

	const { menuGroupsOpen } = useAppSelector((store) => store.app);
	const matchesSM = useMediaQuery(theme.breakpoints.down("lg"));

	const Icon = item.icon as TablerIcon;
	const itemIcon = item?.icon ? (
		<Icon stroke={1.5} size="1.3rem" />
	) : (
		<FiberManualRecordIcon
			sx={{
				width: menuGroupsOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
				height: menuGroupsOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
			}}
			fontSize={level > 0 ? "inherit" : "medium"}
		/>
	);

	let itemTarget = "_self";
	if (item.target) {
		itemTarget = "_blank";
	}

	let listItemProps: {
		component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
		href?: string;
		target?: string;
	} = {
		component: forwardRef<HTMLAnchorElement>((props, ref) => <Link ref={ref} {...props} to={item.url as string} target={itemTarget} />),
	};
	if (item?.external) {
		listItemProps = { component: "a", href: item.url, target: itemTarget };
	}

	const itemHandler = (id: string) => {
		dispatch(setSidebarMenuGroupsOpen(id));
		if (matchesSM) {
			dispatch(setSidebarMenuOpen(false));
		}
	};

	// active menu item on page load
	useEffect(() => {
		const currentIndex = document.location.pathname
			.toString()
			.split("/")
			.findIndex((id) => id === item.id);
		if (currentIndex > -1) {
			dispatch(setSidebarMenuGroupsOpen(item.id));
		}
		// eslint-disable-next-line
	}, []);

	const { borderRadius } = constants;

	return (
		<ListItemButton
			{...listItemProps}
			disabled={item.disabled}
			sx={{
				borderRadius: `${borderRadius}px`,
				mb: 0.5,
				alignItems: "flex-start",
				backgroundColor: level > 1 ? "transparent !important" : "inherit",
				py: level > 1 ? 1 : 1.25,
				pl: `${level * 24}px`,
			}}
			selected={menuGroupsOpen.findIndex((id) => id === item.id) > -1}
			onClick={() => itemHandler(item.id)}
		>
			<ListItemIcon sx={{ my: "auto", minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
			<ListItemText
				primary={
					<Typography variant={menuGroupsOpen.findIndex((id) => id === item.id) > -1 ? "h5" : "body1"} color="inherit">
						{item.title}
					</Typography>
				}
				secondary={
					item.caption && (
						<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
							{item.caption}
						</Typography>
					)
				}
			/>
			{item.chip && (
				<Chip
					color={item.chip.color}
					variant={item.chip.variant}
					size={item.chip.size}
					label={item.chip.label}
					avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
				/>
			)}
		</ListItemButton>
	);
};

export default NavItem;
