import styled from "styled-components";

const darkBorderColor = "rgba(189, 200, 240, 0.2)";
const darkHoverColor = "rgba(189, 200, 240, 0.026)";

export const Styles = styled.div`
	.table-container {
		border-radius: 5px;
		overflow: auto;
		margin-bottom: 20px;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		font-size: 14px;
		td,
		th {
			padding: 16px;
			text-align: left;
			&:first-of-type {
				padding-left: 24px;
			}
			&:last-of-type {
				padding-right: 24px;
			}
		}
		th {
			border-bottom: 1px solid ${(props) => (props.theme === "light" ? "#eee" : darkBorderColor)};
			user-select: none;
			white-space: nowrap;
			font-weight: 600;
			font-size: 12px;
			padding: 15px 16px;
			cursor: pointer;
			.sort-icon {
				font-size: 14px;
				color: ${(props) => (props.theme === "light" ? " #005be6" : "#ffc107")};
				vertical-align: middle;
				&.unsorted {
					color: #909090;
				}
			}
		}
		tr {
			td {
				font-size: 12px;
			}
			&:not(:last-of-type) {
				td {
					border-bottom: 1px solid ${(props) => (props.theme === "light" ? "#eee" : darkBorderColor)};
				}
			}
			&:hover {
				td {
					background-color: ${(props) => (props.theme === "light" ? "#fbfbfb" : darkHoverColor)};
				}
			}
			.expand-table {
				display: inline-block;
				vertical-align: middle;
			}
			.expand-icon {
				font-size: 24px;
				color: #909090;
			}
		}
	}
	.table-pagination {
		display: flex;
		justify-content: flex-end;
	}
`;
