import { Typography } from "@mui/material";
import NavGroup from "./NavGroup";
import getMenuItems from "../../../../menu-items";
import { useAppSelector } from "../../../../hooks";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList: React.FC<{}> = () => {
	const { user } = useAppSelector((store) => store.app);
	const navItems = getMenuItems(user?.role).items.map((item) => {
		switch (item.type) {
			case "group":
				return <NavGroup key={item.id} item={item} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				);
		}
	});

	return <>{navItems}</>;
};

export default MenuList;
