export const PHRASES = {
	/**
	 * Auth phrases
	 */
	auth_email_invalid: "Must be a valid email",
	auth_email_required: "Email is required",
	auth_password_required: "Password is required",
	auth_confirm_password_invalid: "Passwords must match",
	auth_confirm_password_required: "Confirm password is required",
	auth_one_contact_required: "At least one contact field is required",
	auth_recaptcha_required: "Please confirm you are not a robot",
};
