import styled from "styled-components";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useAppSelector } from "../hooks";

interface IVerticalImageMarqueeProps {
	height: number | string;
	images: string[];
	width?: number;
	marqueeProps?: MarqueeProps;
	containerStyle?: React.CSSProperties;
}

const StyledMarqueeItem = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
	border: 1px solid ${(props) => (props.theme === "light" ? "rgba(144, 202, 249, 0.46)" : "#111936")};
	border-radius: 11px;
	overflow: hidden;
	img {
		pointer-events: none;
		transform: rotate(-90deg);
	}
`;

const StyledMarquee = styled(Marquee)`
	.marquee {
		align-items: flex-end;
		user-select: none;
	}
`;

export const VerticalImageMarquee: React.FC<IVerticalImageMarqueeProps> = ({
	height,
	width = 285,
	marqueeProps,
	images,
	containerStyle = {},
}) => {
	const { themeMode } = useAppSelector((store) => store.app);
	return (
		<div style={{ display: "inline-block", position: "relative", verticalAlign: "top", height, width, ...containerStyle }}>
			<StyledMarquee
				gradientWidth={92}
				gradientColor={themeMode === "dark" ? [26, 34, 63] : [227, 242, 253]}
				{...marqueeProps}
				style={{
					width: height,
					height: width,
					transformOrigin: "0 100%",
					transform: `translateY(-${width}px) rotate(90deg)`,
				}}
			>
				{images.map((url, index) => (
					<ImageWithSize src={url} containerWidth={width} key={index} />
				))}
			</StyledMarquee>
		</div>
	);
};

export const ImageWithSize: React.FC<{
	src: string;
	containerWidth: number;
}> = ({ src, containerWidth }) => {
	const [imageDimensions, setImageDimensions] = useState<ImageSize>({ width: 0, height: 0 });
	const { themeMode } = useAppSelector((store) => store.app);
	useEffect(() => {
		const img = new Image();
		img.src = src;

		img.onload = () => {
			const imgWidth = img.width;
			const imgHeight = img.height;
			const proportion = imgWidth / imgHeight;
			const newHeight = (1 / proportion) * containerWidth;

			setImageDimensions({
				height: newHeight,
				width: containerWidth,
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<StyledMarqueeItem theme={themeMode} style={{ height: containerWidth, width: imageDimensions.height }}>
			<img src={src} alt="marquee-item" draggable={false} width={containerWidth} height={imageDimensions.height} />
		</StyledMarqueeItem>
	);
};

type ImageSize = { width: number; height: number };

interface MarqueeProps {
	/**
	 * Inline style for the container div
	 * Type: object
	 * Default: {}
	 */
	style?: React.CSSProperties;
	/**
	 * Class name to style the container div
	 * Type: string
	 * Default: ""
	 */
	className?: string;
	/**
	 * Whether to play or pause the marquee
	 * Type: boolean
	 * Default: true
	 */
	play?: boolean;
	/**
	 * Whether to pause the marquee when hovered
	 * Type: boolean
	 * Default: false
	 */
	pauseOnHover?: boolean;
	/**
	 * Whether to pause the marquee when clicked
	 * Type: boolean
	 * Default: false
	 */
	pauseOnClick?: boolean;
	/**
	 * The direction the marquee is sliding
	 * Type: "left" or "right"
	 * Default: "left"
	 */
	direction?: "left" | "right";
	/**
	 * Speed calculated as pixels/second
	 * Type: number
	 * Default: 20
	 */
	speed?: number;
	/**
	 * Duration to delay the animation after render, in seconds
	 * Type: number
	 * Default: 0
	 */
	delay?: number;
	/**
	 * The number of times the marquee should loop, 0 is equivalent to infinite
	 * Type: number
	 * Default: 0
	 */
	loop?: number;
	/**
	 * Whether to show the gradient or not
	 * Type: boolean
	 * Default: true
	 */
	gradient?: boolean;
	/**
	 * The rgb color of the gradient as an array of length 3
	 * Type: Array<number> of length 3
	 * Default: [255, 255, 255]
	 */
	gradientColor?: [number, number, number];
	/**
	 * The width of the gradient on either side
	 * Type: string
	 * Default: 200
	 */
	gradientWidth?: number | string;
	/**
	 * A callback for when the marquee finishes scrolling and stops. Only calls if loop is non-zero.
	 * Type: Function
	 * Default: null
	 */
	onFinish?: () => void;
	/**
	 * A callback for when the marquee finishes a loop. Does not call if maximum loops are reached (use onFinish instead).
	 * Type: Function
	 * Default: null
	 */
	onCycleComplete?: () => void;
	/**
	 * The children rendered inside the marquee
	 * Type: ReactNode
	 * Default: null
	 */
	children?: React.ReactNode;
}
