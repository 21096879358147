import { Autocomplete, CircularProgress, FormControl, FormHelperText, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RoleChip } from ".";
import { getAllUsers } from "../api";
import { useCustomTheme } from "../hooks";

export const UserSelect: React.FC<{
	onUserSelected?: (user: UserListData | UserListData[] | null) => void;
	handleBlur?: (e: React.FocusEvent<any>) => void;
	multiple?: boolean;
	touched?: boolean;
	error?: string;
	id?: string;
	name?: string;
	selected?: string[];
}> = ({ id = "user-search-input", multiple = false, name = "user", selected = [], touched, error, onUserSelected, handleBlur }) => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState<UserListData[]>([]);
	const [value, setValue] = React.useState<UserListData[]>([]);
	const theme = useCustomTheme();

	useEffect(() => {
		if (multiple) {
			onUserSelected && onUserSelected(value);
		} else {
			onUserSelected && onUserSelected(value[0] || null);
		}

		// eslint-disable-next-line
	}, [value, multiple]);

	useEffect(() => {
		getAllUsers().then((data) => {
			setUsers(data);
			const selectedUsers = selected
				.map((id) => {
					const targetOption = data.find((user) => user._id === id);
					return targetOption;
				})
				.filter((data) => Boolean(data)) as UserListData[];
			setValue(selectedUsers);
			setLoading(false);
		});
		// eslint-disable-next-line
	}, []);

	return (
		<FormControl fullWidth sx={{ marginBottom: 1, marginTop: 1 }}>
			<Autocomplete
				multiple
				id={id}
				value={value}
				loading={loading}
				onChange={(event, newValue) => {
					if (newValue.length) {
						if (multiple) {
							setValue(newValue);
						} else {
							setValue([newValue[newValue.length - 1]]);
						}
					} else {
						setValue([]);
					}
				}}
				sx={{ ...theme.typography.customInput, marginBottom: 0, marginTop: 0 }}
				onBlur={handleBlur}
				style={{ minWidth: 290, maxWidth: "100%" }}
				options={users}
				getOptionLabel={(option) => option.email}
				renderTags={(tagValue, getTagProps) =>
					tagValue.map((option, index) => {
						return <RoleChip role={option.role} label={option.email} sx={{ mr: 1, my: 0.25, maxWidth: "100%" }} key={index} />;
					})
				}
				renderInput={(params) => (
					<TextField
						{...params}
						error={Boolean(error && touched)}
						InputProps={{
							...params.InputProps,
							name: name,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
						label="Пользователь"
						placeholder="Поиск"
					/>
				)}
			/>
			{touched && error && <FormHelperText error>{error}</FormHelperText>}
		</FormControl>
	);
};
