import { Button, Card, Stack, styled } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setBalanceModalOpen } from "../../../redux/slices/app";
import { AnimateButton } from "../../../ui-component";
const CardStyle = styled(Card)<{ mode: ThemeMode }>(({ theme, mode }) => {
	return {
		background: mode === "light" ? theme.palette.primary.light : theme.palette.dark[800],
		overflow: "hidden",
		position: "relative",
		padding: "15px 10px",
	};
});
export const BalanceItem: React.FC<{}> = () => {
	const { themeMode } = useAppSelector((store) => store.app);
	const dispatch = useAppDispatch();

	return (
		<CardStyle mode={themeMode}>
			<Stack direction="row" justifyContent="center">
				<AnimateButton>
					<Button
						variant="contained"
						color="warning"
						sx={{ boxShadow: "none" }}
						onClick={() => {
							dispatch(setBalanceModalOpen(true));
						}}
					>
						Пополнить баланс
					</Button>
				</AnimateButton>
			</Stack>
		</CardStyle>
	);
};
