import { styled } from "@mui/material/styles";
import { Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { AnimateButton } from "../../../ui-component";
import { useAppSelector, useCustomTheme } from "../../../hooks";
import { getPriceUnit } from "../../../utils/price";

const CardStyle = styled(Card)<{ mode: ThemeMode }>(({ theme, mode }) => {
	return {
		background: mode === "light" ? theme.palette.warning.light : theme.palette.dark[800],
		marginBottom: "16px",
		overflow: "hidden",
		position: "relative",
		"&:after": {
			content: '""',
			position: "absolute",
			width: "200px",
			height: "200px",
			border: "19px solid ",
			borderColor: theme.palette.warning.main,
			borderRadius: "50%",
			top: "65px",
			right: "-150px",
		},
		"&:before": {
			content: '""',
			position: "absolute",
			width: "200px",
			height: "200px",
			border: "3px solid ",
			borderColor: theme.palette.warning.main,
			borderRadius: "50%",
			top: "145px",
			right: "-70px",
		},
	};
});

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const BalanceCard: React.FC<{ onCallModal: () => void }> = ({ onCallModal }) => {
	const theme = useCustomTheme();
	const { themeMode, user } = useAppSelector((store) => store.app);

	const userBalance = user?.balance.length ? user.balance[0] : { value: 0, unit: "USD" };

	return (
		<>
			<CardStyle mode={themeMode}>
				<CardContent>
					<Grid container direction="column" spacing={2}>
						<Grid item container justifyContent="space-between" alignItems="center">
							<Typography variant="h4">Баланс</Typography>
							<Typography variant="h3" color={themeMode === "light" ? theme.palette.text.dark : theme.palette.warning.main}>
								{userBalance?.value.toFixed(2)} {getPriceUnit(userBalance.unit as CurrencyUnit)?.sign}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2" color="grey.900" sx={{ opacity: 0.6 }}>
								Свяжитель с менеджером
								<br /> для пополнения Вашего баланса
							</Typography>
						</Grid>
						<Grid item>
							<Stack direction="row">
								<AnimateButton>
									<Button
										variant="contained"
										color="warning"
										sx={{ boxShadow: "none" }}
										onClick={() => {
											onCallModal && onCallModal();
										}}
									>
										Пополнить
									</Button>
								</AnimateButton>
							</Stack>
						</Grid>
					</Grid>
				</CardContent>
			</CardStyle>
		</>
	);
};

export default BalanceCard;
