import React, { useEffect } from "react";
import { Column, FilterTypes, useExpanded, useFilters, UseFiltersColumnProps, usePagination, useSortBy, useTable } from "react-table";
import { ExpandLess, ExpandMore, UnfoldMore } from "@mui/icons-material";
import { Styles } from "./styles";
import { TablePagination, TextField } from "@mui/material";
import { useAppSelector } from "../../hooks";
import { matchSorter } from "match-sorter";

interface ITable<T extends {}> {
	data: T[];
	columns: Column<T>[];
	hiddenColumns?: string[];
	renderRowSubComponent?: (row: T) => JSX.Element;
}

function fuzzyTextFilterFn(rows: any[], id: string, filterValue: string) {
	return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val: string) => !val;

const DefaultColumnFilter: React.FC<{ column: Column & UseFiltersColumnProps<any> }> = ({ column }) => {
	const { filterValue, setFilter } = column;

	return (
		<TextField
			placeholder={`Поиск по ${column.Header}`}
			value={filterValue || ""}
			onChange={(e) => {
				setFilter(e.target.value || undefined);
			}}
			variant="outlined"
			size="small"
		/>
	);
};

export const Table: React.FC<ITable<any>> = ({ columns, data, renderRowSubComponent, hiddenColumns = [] }) => {
	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.

			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows: any[], id: string, filterValue: string) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
				});
			},
		}),
		[],
	);
	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[],
	);

	const {
		// table
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,

		// pagination
		page,
		gotoPage,
		setPageSize,

		visibleColumns,
		setHiddenColumns,
		state: { pageIndex, pageSize },

		// sorting
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes: filterTypes as unknown as FilterTypes<object>,
		},
		useFilters,
		useSortBy,
		useExpanded,
		usePagination,
	);

	useEffect(() => {
		if (hiddenColumns.length) {
			setHiddenColumns(hiddenColumns);
		}
		// eslint-disable-next-line
	}, [hiddenColumns]);

	const { themeMode } = useAppSelector((store) => store.app);

	return (
		<Styles theme={themeMode}>
			<div className="table-container">
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									const sortByProps = column.getSortByToggleProps();

									return (
										<th
											{...column.getHeaderProps({
												...sortByProps,
												style: { ...sortByProps.style, width: column.width || "auto", minWidth: column.minWidth || 0 },
											})}
										>
											{column.render("Header")}
											<span>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<ExpandMore className="sort-icon" />
													) : (
														<ExpandLess className="sort-icon" />
													)
												) : !column.disableSortBy ? (
													<UnfoldMore className="sort-icon unsorted" />
												) : (
													""
												)}
											</span>
										</th>
									);
								})}
							</tr>
						))}
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, key) => {
									return (
										<th style={{ padding: "5px" }} key={key}>
											<div>{column.canFilter ? column.render("Filter") : null}</div>
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);
							return (
								<React.Fragment key={index}>
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
										})}
									</tr>

									{row.isExpanded ? (
										<tr>
											<td colSpan={visibleColumns.length}>{renderRowSubComponent && renderRowSubComponent(row.values)}</td>
										</tr>
									) : null}
								</React.Fragment>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="table-pagination">
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={data.length}
					rowsPerPage={pageSize}
					page={pageIndex}
					onPageChange={(event: unknown, newPage: number) => {
						gotoPage(newPage);
					}}
					onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setPageSize(Number(event.target.value));
						gotoPage(0);
					}}
				/>
			</div>
		</Styles>
	);
};
