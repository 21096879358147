import { Suspense } from "react";
import { Loader } from ".";

// project imports

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable: <T>(component: React.FC) => React.FC<T> = (Component) => (props) =>
	(
		<Suspense fallback={<Loader />}>
			<Component {...props} />
		</Suspense>
	);

export default Loadable;
