import { Avatar, Box, ButtonBase, Typography } from "@mui/material";
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";
import { IconMenu2 } from "@tabler/icons";
import { constants } from "../../../store";
import { useAppDispatch, useAppSelector, useCustomTheme } from "../../../hooks";
import { getPriceUnit } from "../../../utils/price";
import { setBalanceModalOpen } from "../../../redux/slices/app";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header: React.FC<{ handleLeftDrawerToggle: () => void }> = ({ handleLeftDrawerToggle }) => {
	const theme = useCustomTheme();
	const dispatch = useAppDispatch();
	const { themeMode, user } = useAppSelector((store) => store.app);
	const userBalance = user?.balance.length ? user.balance[0] : { value: 0, unit: "USD" };
	return (
		<>
			<Box
				sx={{
					width: 228,
					display: "flex",
					alignItems: "center",
					[theme.breakpoints.down("md")]: {
						width: "auto",
					},
				}}
			>
				<Box component="span" sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}>
					<LogoSection />
				</Box>
				<ButtonBase sx={{ borderRadius: `${constants.borderRadius}px`, overflow: "hidden" }}>
					<Avatar
						variant="rounded"
						sx={{
							...theme.typography.commonAvatar,
							...theme.typography.mediumAvatar,
							transition: "all .2s ease-in-out",
							background: themeMode === "light" ? theme.palette.secondary.light : theme.palette.dark.main,
							color: themeMode === "light" ? theme.palette.secondary.dark : theme.config.menuSelected,
							"&:hover": {
								background: themeMode === "light" ? theme.palette.secondary.dark : theme.config.menuSelected,
								color: theme.palette.secondary.light,
							},
						}}
						onClick={handleLeftDrawerToggle}
						color="inherit"
					>
						<IconMenu2 stroke={1.5} size="1.3rem" />
					</Avatar>
				</ButtonBase>
			</Box>

			<Box sx={{ flexGrow: 1 }} />
			<Typography
				variant="subtitle1"
				color={themeMode === "light" ? theme.palette.text.dark : theme.palette.warning.main}
				sx={{ mr: 2, cursor: "pointer", userSelect: "none" }}
				onClick={() => {
					dispatch(setBalanceModalOpen(true));
				}}
			>
				Баланс: {userBalance?.value.toFixed(2)} {getPriceUnit(userBalance.unit as CurrencyUnit)?.sign}
			</Typography>
			{/* <NotificationSection /> */}
			<ProfileSection />
		</>
	);
};

export default Header;
