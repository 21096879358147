import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import {
	Avatar,
	Box,
	Chip,
	ClickAwayListener,
	Divider,
	Grid,
	IconButton,
	Link,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Popper,
	Stack,
	Typography,
} from "@mui/material";

import PerfectScrollbar from "react-perfect-scrollbar";

import MainCard from "../../../ui-component/cards/MainCard";
import Transitions from "../../../ui-component/extended/Transitions";
import { Brightness4, Brightness7, Check, CopyAll } from "@mui/icons-material";

import { IconLogout, IconSettings, IconUser } from "@tabler/icons";
import { constants } from "../../../store";
import { useAppDispatch, useAppSelector, useCustomTheme } from "../../../hooks";
import { logout } from "../../../api";
import { setBalanceModalOpen, setThemeMode, setUserAuth } from "../../../redux/slices/app";
import BalanceCard from "./BalanceCard";
import { AppModal } from "../../../ui-component";
import copy from "copy-to-clipboard";

// ==============================|| PROFILE MENU ||============================== //

const PAYMENTS_DATA = {
	usdt_trc20: "TL7ycfgewPMykfeq573ZbQwhdyh8HULETc",
	usdt_trc20_link:
		"https://link.trustwallet.com/send?asset=c195_tTR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t&address=TL7ycfgewPMykfeq573ZbQwhdyh8HULETc",
};

const ProfileSection = () => {
	const theme = useCustomTheme();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { themeMode, user, balanceModalOpen } = useAppSelector((store) => store.app);

	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [open, setOpen] = useState(false);

	const [copied, setCopied] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (copied) {
				setCopied(false);
			}
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [copied]);
	/**
	 * anchorRef is used on different componets and specifying one type leads to other components throwing an error
	 * */
	const anchorRef = useRef<any>(null);
	const handleLogout = () => {
		logout().then(() => {
			localStorage.removeItem("token");
			dispatch(setUserAuth(false));
			window.location.reload();
		});
	};

	const handleClose: (event: MouseEvent | TouchEvent) => void = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}
		setOpen(false);
	};

	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number, route: string = "") => {
		setSelectedIndex(index);
		handleClose(event as unknown as MouseEvent);

		if (route && route !== "") {
			navigate(route);
		}
	};
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef?.current?.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const { borderRadius } = constants;

	return (
		<>
			<Chip
				sx={{
					height: "48px",
					alignItems: "center",
					borderRadius: "27px",
					transition: "all .2s ease-in-out",
					borderColor: themeMode === "light" ? theme.palette.primary.light : theme.palette.dark.main,
					backgroundColor: themeMode === "light" ? theme.palette.primary.light : theme.palette.dark.main,
					'&[aria-controls="menu-list-grow"], &:hover': {
						borderColor: theme.palette.primary.main,
						background: `${theme.palette.primary.main}!important`,
						color: theme.palette.primary.light,
						"& svg": {
							stroke: theme.palette.primary.light,
						},
					},
					"& .MuiChip-label": {
						lineHeight: 0,
					},
				}}
				icon={
					<Avatar
						ref={anchorRef}
						aria-controls={open ? "menu-list-grow" : undefined}
						aria-haspopup="true"
						sx={{
							...theme.typography.mediumAvatar,
							backgroundColor: theme.palette.warning.dark,
							margin: "8px 0 8px 8px !important",
							cursor: "pointer",
							"& svg": {
								stroke: `${theme.palette.dark.main} !important`,
							},
						}}
					>
						<IconUser stroke={1.5} color={theme.palette.dark.main} />
					</Avatar>
				}
				label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
				variant="outlined"
				ref={anchorRef}
				aria-controls={open ? "menu-list-grow" : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				color="primary"
			/>
			<Popper
				placement="bottom-end"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				popperOptions={{
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, 14],
							},
						},
					],
				}}
			>
				{({ TransitionProps }) => (
					<Transitions in={open} {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
									<Box sx={{ p: 2 }}>
										<Grid item container justifyContent="space-between">
											<Stack>
												<Stack direction="row" spacing={0.5} alignItems="center">
													<Typography variant="h4">Добро пожаловать!</Typography>
												</Stack>
												<Typography variant="subtitle2">{user?.email}</Typography>
											</Stack>
											<IconButton
												sx={{ ml: 1 }}
												onClick={() => {
													dispatch(setThemeMode(themeMode === "light" ? "dark" : "light"));
												}}
												color="inherit"
											>
												{themeMode === "light" ? <Brightness7 /> : <Brightness4 />}
											</IconButton>
										</Grid>
									</Box>
									<Divider />
									<PerfectScrollbar
										options={{ suppressScrollX: true }}
										style={{ height: "100%", maxHeight: "calc(100vh - 250px)", overflowX: "hidden" }}
									>
										<Box sx={{ p: 2 }}>
											<BalanceCard
												onCallModal={() => {
													dispatch(setBalanceModalOpen(true));
													setOpen(false);
												}}
											/>
											<Divider />
											<List
												component="nav"
												sx={{
													width: "100%",
													maxWidth: 350,
													minWidth: 300,
													backgroundColor: theme.palette.background.paper,
													borderRadius: "10px",
													[theme.breakpoints.down("md")]: {
														minWidth: "100%",
													},
													"& .MuiListItemButton-root": {
														mt: 0.5,
													},
												}}
											>
												<ListItemButton
													sx={{ borderRadius: `${borderRadius}px` }}
													selected={selectedIndex === 0}
													onClick={(event) => handleListItemClick(event, 0, "/settings")}
												>
													<ListItemIcon>
														<IconSettings stroke={1.5} size="1.3rem" />
													</ListItemIcon>
													<ListItemText primary={<Typography variant="body2">Настройки</Typography>} />
												</ListItemButton>
												<ListItemButton sx={{ borderRadius: `${borderRadius}px` }} selected={selectedIndex === 4} onClick={handleLogout}>
													<ListItemIcon>
														<IconLogout stroke={1.5} size="1.3rem" />
													</ListItemIcon>
													<ListItemText primary={<Typography variant="body2">Выход</Typography>} />
												</ListItemButton>
											</List>
										</Box>
									</PerfectScrollbar>
								</MainCard>
							</ClickAwayListener>
						</Paper>
					</Transitions>
				)}
			</Popper>
			<AppModal
				title="Пополнить баланс"
				open={balanceModalOpen}
				modalWidth={400}
				onClose={() => {
					dispatch(setBalanceModalOpen(false));
				}}
			>
				<Box sx={{ textAlign: "center", mb: 2, img: { maxWidth: "100%" } }}>
					<img src="./images/qr.jpeg" alt="qr code" />
				</Box>
				<Typography component="div" sx={{ py: 1, pl: 1, ml: -1 }}>
					Адрес для USDT TRC20: <br />{" "}
					<ListItemButton
						sx={{ px: 1, mx: -1 }}
						onClick={() => {
							copy(PAYMENTS_DATA.usdt_trc20);
							setCopied(true);
						}}
					>
						<ListItemText
							primary={
								<Typography variant="inherit" sx={{ textOverflow: "ellipsis", pr: 1 }} noWrap>
									{PAYMENTS_DATA.usdt_trc20}
								</Typography>
							}
						/>
						{copied ? (
							<Check
								style={{
									color: theme.config.mode === "dark" ? theme.palette.success.main : theme.palette.success.dark,
									opacity: copied ? 1 : 0,
									strokeDasharray: 50,
									strokeDashoffset: copied ? 0 : -50,
									transition: "all 300ms ease-in-out",
								}}
							/>
						) : (
							<CopyAll sx={{ pointerEvents: "none" }} />
						)}
					</ListItemButton>
				</Typography>
				<Typography component="p" sx={{ py: 1 }}>
					Trust Wallet:
					<br />
					<Link
						sx={{
							wordBreak: "break-all",
						}}
						href={PAYMENTS_DATA.usdt_trc20_link}
						target="_blank"
					>
						{PAYMENTS_DATA.usdt_trc20_link}
					</Link>
				</Typography>

				<Typography component="p" sx={{ py: 1 }}>
					1. Отсканируйте QR код
				</Typography>
				<Typography component="p" sx={{ py: 1 }}>
					2. Переведите на кошелек нужную сумму
				</Typography>
				<Typography component="p" sx={{ py: 1 }}>
					3. Напишите{" "}
					<Link href="https://t.me/zhenya_comunity" target="_blank">
						менеджеру
					</Link>
					, сбросив скриншот
				</Typography>
			</AppModal>
		</>
	);
};

export default ProfileSection;
