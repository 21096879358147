import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IOffersSliceState {
	offersLoaded: boolean;
	offers: OfferDetails[];
}

const initialState: IOffersSliceState = {
	offers: [],
	offersLoaded: false,
};

const offer = createSlice({
	name: "offer",
	initialState,
	reducers: {
		setOffers: (state, action: PayloadAction<OfferDetails[]>) => {
			state.offers = action.payload;
		},
		addNewOffer: (state, action: PayloadAction<OfferDetails>) => {
			state.offers = [action.payload, ...state.offers];
		},
		saveOffer: (state, action: PayloadAction<OfferDetails>) => {
			state.offers = state.offers.map((offer) => {
				if (offer._id === action.payload._id) {
					return action.payload;
				} else {
					return offer;
				}
			});
		},
		setOffersLoaded: (state, action: PayloadAction<boolean>) => {
			state.offersLoaded = action.payload;
		},
	},
});

export const { setOffers, addNewOffer, saveOffer, setOffersLoaded } = offer.actions;

export default offer.reducer;
