import { configureStore } from "@reduxjs/toolkit";
import app from "./slices/app";
import user from "./slices/user";
import notification from "./slices/notification";
import transaction from "./slices/transaction";
import domain from "./slices/domain";
import offer from "./slices/offer";
import tracker from "./slices/tracker";
import whitePage from "./slices/white-page";
import tool from "./slices/tool";
import antic from "./slices/antic";
import news from "./slices/news";
import creative from "./slices/creative";
import account from "./slices/account";
import registration from "./slices/registration";
import pack from "./slices/pack";

const store = configureStore({
	reducer: {
		app,
		user,
		account,
		notification,
		transaction,
		domain,
		offer,
		tracker,
		antic,
		whitePage,
		tool,
		news,
		creative,
		registration,
		pack,
	},
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
