import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ITransactionsSliceState {
	transactions: BalanceTransaction[];
	transactionsLoaded: boolean;
	statistics: TransactionStatisticItem[];
}

const initialState: ITransactionsSliceState = {
	transactions: [],
	transactionsLoaded: false,
	statistics: [],
};

const transaction = createSlice({
	name: "transaction",
	initialState,
	reducers: {
		setTransactions: (state, action: PayloadAction<BalanceTransaction[]>) => {
			state.transactions = action.payload;
		},
		setTransactionsLoaded: (state, action: PayloadAction<boolean>) => {
			state.transactionsLoaded = action.payload;
		},
		setTransactionStatistic: (state, action: PayloadAction<TransactionStatisticItem[]>) => {
			state.statistics = action.payload;
		},
	},
});

export const { setTransactions, setTransactionStatistic, setTransactionsLoaded } = transaction.actions;
export default transaction.reducer;
