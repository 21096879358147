import { Close } from "@mui/icons-material";
import { Grid, IconButton, Modal, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { MainCard } from "..";
import { useAppSelector } from "../../hooks";
interface IAppModalProps {
	open: boolean;
	title: string;
	subheader?: string;
	modalWidth?: number;
	onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined;
	sx?: SxProps<Theme>;
}
export const AppModal: React.FC<IAppModalProps> = ({ open, title, subheader, modalWidth, onClose, children, sx }) => {
	const { themeMode } = useAppSelector((store) => store.app);
	return (
		<Modal open={open} onClose={onClose} sx={{ overflowX: "hidden", overflowY: "auto" }}>
			<Grid
				container
				maxWidth={modalWidth || 600}
				sx={{
					margin: "1.75rem auto",
					position: "relative",
					minHeight: "calc(100% - (1.75rem * 2))",
					display: "flex",
					alignItems: "center",
					pointerEvents: "none",
					outline: "none",
				}}
			>
				<Grid
					item
					onClick={(e) => {
						e.stopPropagation();
					}}
					sx={{
						outline: "none",
						width: "100%",
						pointerEvents: "auto",
						...sx,
					}}
				>
					<MainCard
						title={title}
						subheader={subheader}
						secondary={
							<IconButton
								size="small"
								sx={{ color: themeMode === "light" ? "grey" : "white" }}
								onClick={(e) => onClose && onClose(e, "escapeKeyDown")}
							>
								<Close fontSize="small" />
							</IconButton>
						}
					>
						{children}
					</MainCard>
				</Grid>
			</Grid>
		</Modal>
	);
};
