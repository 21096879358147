import { CircularProgress, Typography, Box } from "@mui/material";
import { SxProps } from "@mui/system";

import React from "react";
import { useAppSelector } from "../hooks";

export const CircularProgressWithLabel: React.FC<{ value: number; sx?: SxProps }> = ({ value, sx = {} }) => {
	const { themeMode } = useAppSelector((store) => store.app);
	return (
		<Box sx={{ position: "relative", display: "inline-flex", ...sx }}>
			<CircularProgress variant="determinate" value={value} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typography variant="caption" component="div" color={themeMode === "light" ? "black" : "white"}>{`${Math.round(value)}%`}</Typography>
			</Box>
		</Box>
	);
};
