import React from "react";
import { Chip, Avatar } from "@mui/material";
import { SxProps } from "@mui/system";

export const CountryChip: React.FC<{ code: CountryCode; size?: "small" | "medium"; otherProps?: any; sx?: SxProps }> = ({
	code,
	otherProps = {},
	size,
	sx = {},
}) => {
	const imgSrcSvg = `https://flagcdn.com/${code.toLowerCase()}.svg`;
	return <Chip label={code} sx={sx} size={size} avatar={<Avatar alt={code} src={imgSrcSvg} />} {...otherProps} />;
};
