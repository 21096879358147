import { Box, Drawer, useMediaQuery } from "@mui/material";

import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

import MenuList from "./MenuList";
import LogoSection from "../LogoSection";
import { constants } from "../../../store";
import { useCustomTheme } from "../../../hooks";
import { BalanceItem } from "./BalanceItem";

const Sidebar: React.FC<{ drawerOpen: boolean; drawerToggle: () => void }> = ({ drawerOpen, drawerToggle }) => {
	const theme = useCustomTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

	const drawer = (
		<>
			<Box sx={{ display: { xs: "block", md: "none" } }}>
				<Box sx={{ display: "flex", p: 2, mx: "auto", alignItems: "center" }}>
					<LogoSection />
				</Box>
			</Box>
			<BrowserView>
				<PerfectScrollbar
					component="div"
					style={{
						height: !matchUpMd ? "calc(100vh - 100px)" : "calc(100vh - 88px)",
						paddingLeft: "16px",
						paddingRight: "16px",
					}}
				>
					<BalanceItem />
					<MenuList />
				</PerfectScrollbar>
			</BrowserView>
			<MobileView>
				<Box sx={{ px: 2 }}>
					<MenuList />
				</Box>
			</MobileView>
		</>
	);

	return (
		<Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? constants.drawerWidth : "auto" }} aria-label="mailbox folders">
			<Drawer
				variant={matchUpMd ? "persistent" : "temporary"}
				anchor="left"
				open={drawerOpen}
				onClose={drawerToggle}
				sx={{
					"& .MuiDrawer-paper": {
						width: constants.drawerWidth,
						background: theme.palette.background.default,
						color: theme.palette.text.primary,
						borderRight: "none",
						[theme.breakpoints.up("md")]: {
							top: "88px",
						},
					},
				}}
				ModalProps={{ keepMounted: true }}
				color="inherit"
			>
				{drawer}
			</Drawer>
		</Box>
	);
};

export default Sidebar;
