import axios, { AxiosRequestHeaders } from "axios";
import { setUserAuth, setUserData } from "../redux/slices/app";
import store from "../redux/store";
import { API_URL } from "../store/constants";

const Api = axios.create({
	withCredentials: true,
	baseURL: API_URL,
});

Api.interceptors.request.use((config) => {
	(config.headers as AxiosRequestHeaders).Authorization = `Bearer ${localStorage.getItem("token")}`;
	return config;
});

Api.interceptors.response.use(
	(config) => {
		return config;
	},
	async (error) => {
		const originalRequest = error.config;

		if (error.response.status === 401 && error.config && !error.config._isRetry) {
			originalRequest._isRetry = true;
			try {
				const response = await axios.post<IAuthResponse>(`${API_URL}/auth/refresh`, {}, { withCredentials: true });
				localStorage.setItem("token", response.data.accessToken);
				return Api.request(originalRequest);
			} catch (e) {
				console.log(e);
			}
		}
		if (error.response.status === 401 && error.config && error.config._isRetry) {
			localStorage.removeItem("token");
			store.dispatch(setUserAuth(false));
			store.dispatch(setUserData(null));
		}

		throw error;
	},
);

/**
 * Auth
 */
export const login = async (data: ILoginRequest) => {
	return await Api.post<IAuthResponse>("/auth/login", data).then((resp) => resp.data);
};

export const logout = async () => {
	return await Api.post<IAuthResponse>("/auth/logout").then((resp) => resp.data);
};

export const register = async (data: IRegisterRequest) => {
	return await Api.post<{ message: string }>("/auth/registration", data).then((resp) => resp.data);
};

export const forgot = async (data: IForgotRequest) => {
	return await Api.post<{ status: string; message: string }>("/auth/forgot-password", data).then((resp) => resp.data);
};

export const newPassword = async (data: INewPasswordRequest, token: string) => {
	return await Api.post<IAuthResponse>(`/auth/reset-password/${token}`, data).then((resp) => resp.data);
};

/**
 * Main
 */
export const getMe = async () => {
	return await Api.get<AccountData>("/users/me").then((resp) => resp.data);
};

/**
 * Settings
 */
export const changePassword = async (data: IChangePasswordRequest) => {
	return await Api.post<IAuthResponse>(`/auth/change-password`, data).then((resp) => resp.data);
};

/**
 * Users
 */
export const getAllUsers = async () => {
	return await Api.get<UserData[]>("/users/list").then((resp) => resp.data);
};

export const getAllAccounts = async () => {
	return await Api.get<AccountData[]>("/users").then((resp) => resp.data);
};

export const updateUser = async (id: string, data: IUserUpdateRequest) => {
	return await Api.put<UserData>(`/users/${id}`, data).then((resp) => resp.data);
};

export const createUser = async (data: IUserCreateRequest) => {
	return await Api.post<AccountData>(`/users/create`, data).then((resp) => resp.data);
};

/**
 * Balance
 */
export const getAllBalances = async () => {
	return await Api.get<BalanceTransaction[]>(`/balances`).then((resp) => resp.data);
};
export const createUserBalance = async (id: string) => {
	return await Api.post<BalanceData>(`/balances/create`, { userId: id }).then((resp) => resp.data);
};

export const updateUserBalance = async (data: IChangeBalanceRequest) => {
	return await Api.post<BalanceTransaction>(`/balances/change`, data).then((resp) => resp.data);
};

/**
 * Transactions
 */

export const getMyBalanceTransactions = async (limit?: number) => {
	const limitQuery = limit ? `?limit=${limit}` : "";
	return await Api.get<BalanceTransaction[]>(`/transactions/my${limitQuery}`).then((resp) => resp.data);
};
export const getTransactionStatistics = async () => {
	return await Api.get<TransactionStatisticItem[]>(`/transactions/statistics`).then((resp) => resp.data);
};

/**
 * Upload files
 */

export const uploadImage = async (file: File, onUploadProgress?: (percent: number) => void) => {
	const data = new FormData();
	data.append("image", file);
	return await Api.post<IImageUploadResponse>(`/uploads/image`, data, {
		onUploadProgress: function (progressEvent) {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			onUploadProgress && onUploadProgress(percentCompleted);
		},
	}).then((resp) => resp.data);
};

/**
 * Offers-category
 */

export const getOffersCategories = async () => {
	return await Api.get<OfferCategory[]>(`/offers-category`).then((resp) => resp.data);
};
export const addOffersCategory = async (data: ICreateOfferCategoryRequest) => {
	return await Api.post<OfferCategory>(`/offers-category`, data).then((resp) => resp.data);
};
export const updateOfferCategory = async (id: string, update: ICreateOfferCategoryRequest) => {
	return await Api.patch<OfferCategory>(`/offers-category/${id}`, update).then((resp) => resp.data);
};
export const deleteOfferCategory = async (id: string) => {
	return await Api.delete<OfferCategory>(`/offers-category/${id}`).then((resp) => resp.data);
};

/**
 * Offers
 */
export const getAllOffers = async () => {
	return await Api.get<OfferDetails[]>(`/offers`).then((resp) => resp.data);
};
export const getOfferById = async (id: string) => {
	return await Api.get<OfferDetails>(`/offers/${id}`).then((resp) => resp.data);
};
export const addOffer = async (data: ICreateOfferRequest) => {
	return await Api.post<OfferDetails>(`/offers`, data).then((resp) => resp.data);
};
export const removeOffer = async (id: string) => {
	return await Api.delete<OfferDetails>(`/offers/${id}`).then((resp) => resp.data);
};
export const updateOffer = async (id: string, data: ICreateOfferRequest) => {
	return await Api.patch<OfferDetails>(`/offers/${id}`, data).then((resp) => resp.data);
};

/**
 * Trackers
 */
export const getAllTrackers = async () => {
	return await Api.get<TrackerDetails[]>(`/trackers`).then((resp) => resp.data);
};
export const orderTracker = async (id: string) => {
	return await Api.post<IOrderToolResponse>(`/trackers/buy/${id}`).then((resp) => resp.data);
};
/**
 * Antics
 */
export const getAllAntics = async () => {
	return await Api.get<AnticDetails[]>(`/antics`).then((resp) => resp.data);
};
export const orderAntic = async (id: string) => {
	return await Api.post<IOrderToolResponse>(`/antics/buy/${id}`).then((resp) => resp.data);
};
/**
 * User tools
 */
export const getUserTools = async () => {
	return await Api.get<UserTool>(`/tools`).then((resp) => resp.data);
};

/**
 * White Pages
 */
export const getWhitePageOrders = async () => {
	return await Api.get<WhitePageOrder[]>(`/white-pages/orders`).then((resp) => resp.data);
};
export const orderWhitePage = async (geo: string[]) => {
	return await Api.post<IOrderWhitePagesResponse>(`/white-pages/order/`, { geo }).then((resp) => resp.data);
};

/**
 * Account
 */
export const orderAccount = async (order: IOrderAccountRequest) => {
	return await Api.post<IOrderAccountResponse>(`/account/order/`, order).then((resp) => resp.data);
};
export const getAllAccountOrders = async () => {
	return await Api.get<AccountOrder[]>(`/account/orders`).then((resp) => resp.data);
};

/**
 * News
 */
export const getAllNews = async () => {
	return await Api.get<NewsDetails[]>(`/news`).then((resp) => resp.data);
};
export const removeNews = async (id: string) => {
	return await Api.delete<NewsDetails>(`/news/${id}`).then((resp) => resp.data);
};
export const getAllMentorNews = async () => {
	return await Api.get<DailyNews[]>(`/news/mentor`).then((resp) => resp.data);
};
export const getNewsById = async (id: string) => {
	return await Api.get<NewsDetails>(`/news/${id}`).then((resp) => resp.data);
};
export const addNewsPost = async (data: ICreateNewsRequest) => {
	return await Api.post<NewsDetails>(`/news`, data).then((resp) => resp.data);
};
export const updateNews = async (id: string, data: ICreateNewsRequest) => {
	return await Api.patch<NewsDetails>(`/news/${id}`, data).then((resp) => resp.data);
};

/**
 * Creatives
 */

export const getAllCreativeOrders = async () => {
	return await Api.get<CreativeOrder[]>(`/creatives/orders`).then((resp) => resp.data);
};
export const orderCreatives = async (data: IOrderCreativesRequest) => {
	return await Api.post<IOrderCreativesResponse>(`/creatives/order/`, data).then((resp) => resp.data);
};

/**
 * Domains
 */
export const getAllDomains = async () => {
	return await Api.get<DomainDetails[]>(`/domains`).then((resp) => resp.data);
};
export const getUserDomains = async () => {
	return await Api.get<DomainDetails[]>(`/domains/personal`).then((resp) => resp.data);
};
export const uploadDomainsCsv = async (file: File) => {
	const data = new FormData();
	data.append("csv", file);
	return await Api.post<IDomainsImportResponse>(`/domains/import-csv`, data).then((resp) => resp.data);
};
export const delegateDomain = async (domain: string, user: string) => {
	return Api.post<DomainDetails>(`domains/${domain}/delegate`, { user }).then((resp) => resp.data);
};
export const removeDomain = async (id: string) => {
	return await Api.delete<DomainDetails>(`/domains/${id}`).then((resp) => resp.data);
};
export const checkDomainBan = async (domain: string) => {
	return Api.post<DomainDetails>(`/domains/${domain}/check`).then((resp) => resp.data);
};

/**
 * Registrations
 */
export const getAllRegistrations = async () => {
	return await Api.get<RegistrationDetails[]>(`/registrations`).then((resp) => resp.data);
};
export const deleteRegistration = async (id: string) => {
	return await Api.delete<RegistrationDetails>(`/registrations/${id}`).then((resp) => resp.data);
};
export const confirmRegistration = async (id: string) => {
	return await Api.patch<RegistrationDetails>(`/registrations/${id}`, { confirmed: true }).then((resp) => resp.data);
};

/**
 * Packs
 */
export const getAllPacks = async () => {
	return await Api.get<PackDetails[]>(`/packs`).then((resp) => resp.data);
};
export const orderPack = async (id: string) => {
	return await Api.post<IOrderPackResponse>(`/packs/order`, { pack: id }).then((resp) => resp.data);
};
