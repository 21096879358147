import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from ".";
import { getMe, getUserTools } from "../api";
import { setUserData } from "../redux/slices/app";
import { setAntic, setTracker } from "../redux/slices/tool";

export const useApp = () => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((store) => store.app);
	useEffect(() => {
		if (!user) {
			// user data not fetched
			fetchAccountInfo();
		}
		// eslint-disable-next-line
	}, [user]);

	const fetchAccountInfo = async () => {
		try {
			const userInfo = await getMe();
			const userTools = await getUserTools();
			if (userTools.tracker) {
				dispatch(setTracker(userTools.tracker));
			}
			if (userTools.antic) {
				dispatch(setAntic(userTools.antic));
			}
			dispatch(setUserData(userInfo));
		} catch (error) {}
	};
	return {
		user,
		fetchAccountInfo,
	};
};
