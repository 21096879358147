import React from "react";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";

export const PdfCard: React.FC<{ title: string; pdfLink: string; image: string }> = ({ title, pdfLink, image }) => {
	return (
		<Card sx={{ maxWidth: 290, m: 1 }}>
			<CardActionArea component={"a"} target="_blank" href={pdfLink}>
				<CardMedia component="img" image={image} alt={title} />
				<CardContent>
					<Typography gutterBottom variant="h3" component="div">
						{title}
					</Typography>
					<Typography variant="h4" component="div" sx={{ color: "#f44336" }}>
						Бесплатно
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
