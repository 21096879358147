import React from "react";
import { Grid, Button } from "@mui/material";
import { AnimateButton } from "../";

export const ModalSubmitButton: React.FC<{ disabled?: boolean; title?: string }> = ({ disabled, title = "Сохранить" }) => {
	return (
		<Grid item xs={6} sx={{ mt: 2, mx: "auto" }}>
			<AnimateButton>
				<Button disableElevation disabled={disabled} fullWidth size="large" type="submit" variant="contained" color="secondary">
					{title}
				</Button>
			</AnimateButton>
		</Grid>
	);
};
