import { styled } from "@mui/system";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const AppTooltip = styled(({ className, mode, ...props }: TooltipProps & { mode: ThemeMode }) => {
	return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme, mode }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: mode === "light" ? theme.palette.common.white : theme.palette.dark[800],
		color: mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#8492c4",
		border: mode === "light" ? "1px solid #eeeeee" : "1px solid #404968",
		fontSize: 11,
	},
}));
