import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserSliceState {
	activeUser: AccountData | null;
}
const initialState: IUserSliceState = {
	activeUser: null,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setActiveUser: (state, action: PayloadAction<AccountData | null>) => {
			state.activeUser = action.payload;
		},
	},
});

export const { setActiveUser } = userSlice.actions;
export default userSlice.reducer;
