import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICreativeState {
	creativeOrders: CreativeOrder[];
	creativeOrdersLoaded: boolean;
}
const initialState: ICreativeState = {
	creativeOrders: [],
	creativeOrdersLoaded: false,
};

const creative = createSlice({
	name: "creative",
	initialState,
	reducers: {
		setCreativesOrders: (state, action: PayloadAction<CreativeOrder[]>) => {
			state.creativeOrders = action.payload;
		},
		setCreativeOrdersLoaded: (state, action: PayloadAction<boolean>) => {
			state.creativeOrdersLoaded = action.payload;
		},
		addCreativeOrder: (state, action: PayloadAction<CreativeOrder>) => {
			state.creativeOrders = [action.payload, ...state.creativeOrders];
		},
	},
});

export const { setCreativesOrders, addCreativeOrder, setCreativeOrdersLoaded } = creative.actions;

export default creative.reducer;
