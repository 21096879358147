import { useState } from "react";
import * as Yup from "yup";
import { login } from "../api";
import { PHRASES } from "../store";
import { useApp, useAppDispatch } from ".";
import { setUserAuth } from "../redux/slices/app";

export const useAuthLogin = () => {
	const initialFormValues = { email: "", password: "", recaptcha: "", submit: null };
	const [rememberChecked, setRememberChecked] = useState<boolean>(true);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const { fetchAccountInfo } = useApp();

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(PHRASES.auth_email_invalid).max(255).required(PHRASES.auth_email_required),
		password: Yup.string().max(255).required(PHRASES.auth_password_required),
		recaptcha: Yup.string().required(PHRASES.auth_recaptcha_required),
	});

	const handleLogin = async (data: ILoginRequest) => {
		try {
			const responseData = await login(data);
			const accessToken = responseData.accessToken;
			localStorage.setItem("token", accessToken);
			await fetchAccountInfo();
			dispatch(setUserAuth(true));
		} catch (error) {
			throw error;
		}
	};

	return {
		initialFormValues,
		rememberChecked,
		showPassword,
		validationSchema,
		setRememberChecked,
		handleClickShowPassword,
		handleLogin,
	};
};
