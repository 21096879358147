import { lazy } from "react";
import { Navigate } from "react-router";

import MainLayout from "../layout/MainLayout";
import { Loadable } from "../ui-component";

const Users = Loadable(lazy(() => import("../views/pages/Users")));
const Balance = Loadable(lazy(() => import("../views/pages/Balance")));
const News = Loadable(lazy(() => import("../views/pages/News")));
const AddOffer = Loadable<{ edit?: boolean }>(lazy(() => import("../views/pages/Offers/AddOffer")));
const AddNews = Loadable<{ edit?: boolean }>(lazy(() => import("../views/pages/News/AddNews")));
const ViewNews = Loadable(lazy(() => import("../views/pages/News/ViewNews")));
const DomainsAdmin = Loadable(lazy(() => import("../views/pages/Domains/DomainsAdmin")));
const Registrations = Loadable(lazy(() => import("../views/pages/Registrations")));

// ==============================|| MAIN ROUTING ||============================== //

const ManagerRoutes = (auth: boolean) => {
	return {
		path: "/",
		element: auth ? <MainLayout /> : <Navigate to="login" replace />,
		children: [
			{
				path: "/users",
				element: <Users />,
			},
			{
				path: "/balance",
				element: <Balance />,
			},
			{
				path: "/news",
				element: <News />,
			},
			{
				path: "/registrations",
				element: <Registrations />,
			},
			{
				path: "/domains/admin",
				element: <DomainsAdmin />,
			},
			{
				path: "/news/add",
				element: <AddNews />,
			},
			{
				path: "/news/view/:id",
				element: <ViewNews />,
			},
			{
				path: "/news/:id",
				element: <AddNews edit />,
			},
			{
				path: "/offers/new",
				element: <AddOffer />,
			},
			{
				path: "/offers/:id",
				element: <AddOffer edit />,
			},
		],
	};
};

export default ManagerRoutes;
