import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserToolState {
	antic: AnticDetails | null;
	tracker: TrackerDetails | null;
}
const initialState: IUserToolState = {
	antic: null,
	tracker: null,
};

const tool = createSlice({
	name: "tool",
	initialState,
	reducers: {
		setTracker: (state, action: PayloadAction<TrackerDetails>) => {
			state.tracker = action.payload;
		},
		setAntic: (state, action: PayloadAction<AnticDetails>) => {
			state.antic = action.payload;
		},
	},
});

export const { setTracker, setAntic } = tool.actions;
export default tool.reducer;
