import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IAccountsState {
	orders: AccountOrder[];
	ordersLoaded: boolean;
}
const initialState: IAccountsState = {
	orders: [],
	ordersLoaded: false,
};

const account = createSlice({
	name: "account",
	initialState,
	reducers: {
		setAccountOrders: (state, action: PayloadAction<AccountOrder[]>) => {
			state.orders = action.payload;
		},
		addAccountOrder: (state, action: PayloadAction<AccountOrder>) => {
			state.orders = [action.payload, ...state.orders];
		},
		setAccountOrdersLoaded: (state, action: PayloadAction<boolean>) => {
			state.ordersLoaded = action.payload;
		},
	},
});

export const { setAccountOrders, addAccountOrder, setAccountOrdersLoaded } = account.actions;

export default account.reducer;
