import React from "react";
import { useAppSelector } from "../../hooks";

export const ProtectedNode: React.FC<{ roles?: UserRole[] }> = ({ roles = ["manager", "admin"], children }) => {
	const { user } = useAppSelector((store) => store.app);
	if (!user) {
		return null;
	}
	const canView = roles.indexOf(user.role) !== -1;

	return canView ? <>{children}</> : null;
};
