import * as Yup from "yup";
import { forgot } from "../api";
import { PHRASES } from "../store";

export const useAuthForgot = () => {
	const initialFormValues = { email: "", recaptcha: "", submit: null };

	const validationSchema = Yup.object().shape({
		email: Yup.string().email(PHRASES.auth_email_invalid).max(255).required(PHRASES.auth_email_required),
		recaptcha: Yup.string().required(PHRASES.auth_recaptcha_required),
	});

	const handleForgotSubmit = async (data: IForgotRequest) => {
		try {
			await forgot(data);
		} catch (error) {
			throw error;
		}
	};

	return {
		initialFormValues,
		validationSchema,
		handleForgotSubmit,
	};
};
