// assets
import { IconBrandTelegram } from "@tabler/icons";
import { MenuGroup } from "../typings/menu";

const other: MenuGroup = {
	id: "external-links",
	type: "group",
	children: [
		{
			id: "support",
			title: "Поддержка",
			type: "item",
			url: "https://t.me/zhenya_comunity",
			icon: IconBrandTelegram,
			external: true,
			target: true,
		},
	],
};

export default other;
