import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IDomainState {
	activeDomain: DomainDetails | null;
	userDomains: DomainDetails[];
	userDomainsLoaded: boolean;

	allDomains: DomainDetails[];
	allDomainsLoaded: boolean;
}
const initialState: IDomainState = {
	activeDomain: null,
	userDomains: [],
	userDomainsLoaded: false,

	allDomains: [],
	allDomainsLoaded: false,
};

const domainSlice = createSlice({
	name: "domain",
	initialState,
	reducers: {
		setActiveDomain: (state, action: PayloadAction<DomainDetails | null>) => {
			state.activeDomain = action.payload;
		},
		setUserDomains: (state, action: PayloadAction<DomainDetails[]>) => {
			state.userDomains = action.payload;
		},
		setUserDomainsLoaded: (state, action: PayloadAction<boolean>) => {
			state.userDomainsLoaded = action.payload;
		},
		setAllDomains: (state, action: PayloadAction<DomainDetails[]>) => {
			state.allDomains = action.payload;
		},
		importNewDomains: (state, action: PayloadAction<DomainDetails[]>) => {
			state.allDomains = [...action.payload, ...state.allDomains];
		},
		setAllDomainsLoaded: (state, action: PayloadAction<boolean>) => {
			state.allDomainsLoaded = action.payload;
		},
		setDomainOwner: (state, action: PayloadAction<DomainDetails>) => {
			state.allDomains = state.allDomains.map((domain) => {
				if (domain._id === action.payload._id) {
					return { ...domain, user: action.payload.user };
				} else {
					return domain;
				}
			});
		},
		setDomainBan: (state, action: PayloadAction<DomainDetails>) => {
			state.userDomains = state.userDomains.map((domain) => {
				if (domain._id === action.payload._id) {
					return { ...domain, banned: action.payload.banned };
				} else {
					return domain;
				}
			});
		},
		setRemovedDomain: (state, action: PayloadAction<DomainDetails>) => {
			state.allDomains = state.allDomains.filter((domain) => {
				if (domain._id === action.payload._id) {
					return false;
				} else {
					return true;
				}
			});
		},
	},
});

export const {
	setActiveDomain,
	setUserDomains,
	setUserDomainsLoaded,
	setAllDomains,
	setAllDomainsLoaded,
	setDomainOwner,
	setRemovedDomain,
	importNewDomains,
	setDomainBan,
} = domainSlice.actions;
export default domainSlice.reducer;
